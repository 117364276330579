import * as React from 'react'
import { styled } from '@mui/system';
import TopNav from './top-nav'
import CalendarSide from './calendar-side';
import Calendar from './calendar'
import moment from 'moment';
import './calendar.css'

const CalendarContainer = styled('div')({
    display: 'flex',
    height: '100%',
    width: 'calc(100% - 24px)',
    flexDirection: 'column',
    padding: 8,
})

const CalendarBody = styled('div')({
    display: 'flex',
    height: '90%',
    overflow: 'hidden'
})


export const CalendarContext = React.createContext({})

export default class CalendarDash extends React.Component {

    today = moment()

    state = {
        daysInView: this.getDaysInMonthView(this.today.get('month'), this.today.get('year')),
        monthInView: this.today.get('month'),
        yearInView: this.today.get('year'),
        dateInView: this.today,
       setDatesInView: (month, year) => {            
            const m = moment()
            m.set('year', year)
            m.set('month', month)
            this.setState({
                daysInView: this.getDaysInMonthView(month, year),
                monthInView: month,
                yearInView: year,
                dateInView: m
            })
        },
        setToToday: () => {
            const m = moment()
            this.setState({
                daysInView: this.getDaysInMonthView(m.get('month'), m.get('year')),
                monthInView: m.get('month'),
                yearInView: m.get('year'),
                dateInView: m
            })
        }
    }

    getDaysInMonthView(monthInt, year) {
        const m = moment()
        m.set('year', year)
        m.set('month', monthInt)
        // const first = m.startOf('month')
        // const previousMonth = m.clone().subtract(1, 'months')
        // const nextMonth = m.clone().add(1, 'months')
        let iterDate = m.clone().startOf('month').subtract(m.clone().startOf('month').day(), 'days')
        const days = []
        let lastDay = m.clone().endOf('month').add(6 - m.endOf('month').day(), 'days')
        if (m.endOf('month').day() === 7) {
            lastDay = m.clone().endOf('month')
        }
        while (iterDate < lastDay) {
            days.push(iterDate.clone())
            iterDate.add(1, 'days')
        }
        return days
    }

    render() {
        return <CalendarContext.Provider value={this.state}> 
            <CalendarContainer>                
                <TopNav></TopNav>
                <CalendarBody>
                    <CalendarSide></CalendarSide>
                    <Calendar></Calendar>
                </CalendarBody>
            </CalendarContainer>
        </CalendarContext.Provider>
    }
}