import Shepherd from 'shepherd.js';

require('shepherd.js/dist/css/shepherd.css');

const tour = new Shepherd.Tour({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shadow-md bg-purple-dark',
      scrollTo: true
    }
});

const buttons = [
  {
    text: 'Skip',
    action: tour.next
  },
  {
    text: 'End Tour',
    action: tour.complete
  }
]
  
tour.addStep({
  id: 'create-board',
  text: `Welcome to OKR Calendar! Let's start by creating your first board.`,
  attachTo: {
    element: '#create-board',
    on: 'bottom'
  },
  classes: 'example-step-extra-class',
  buttons
});

tour.addStep({
  id: 'create-board-dialog',
  text: `Enter a name for your board, and up to five characters for a friendly ID. We'll use the friendly ID to identify objectives and key results attached to this board.`,
  attachTo: {
    element: '#create-board-dialog',
    on: 'top'
  },
  classes: 'example-step-extra-class',
  buttons
});

export default tour