import React, { memo, useContext, useState } from 'react';
import { Handle } from 'reactflow';
import { styled } from '@mui/system';
import { useSearchParams  } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { OkrContext } from '../../../contexts/okr-context';
import './okr-node.css'



const getInitials = (name) => { //TODO reused, move
	if (!name) { return ''}
    const initials = name.split(' ')
    if (initials.length > 1) {
        return `${initials[0][0]}${initials[1][0]}`.toUpperCase()
    } else {
        return name[0].toUpperCase()
    }
}


const OkrTile = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    // border: 'solid 2px lightblue',
    // borderRadius: '4px',
    width: '100%',
    cursor: 'pointer'
})

const OkrTileIcon = styled('p')((props) => ({
    width: '18px',
	minWidth: '18px',
    height: '18px',
    borderRadius: '50%',
    background: `conic-gradient(${props.status} ${props.completion}%, ${props.statusLo} ${props.completion}%) border-box`,
    margin: '4px',
    fontSize: '7px',
	fontWeight: 300,
	textAlign:'center',
	lineHeight: '18px'
}))

const stringToColor = (string) => {
	if (!string) { return '#fff' }
	const color = JSON.parse(string)
	return `rgba(${color.r},${color.g},${color.b},${color.a})`
}

//TODO on drag start, make avaialable targets light up
export default memo((props) => {
	const data = props.data
	const isConnectable = props.isConnectable
	const { state, dispatch } = useContext(OkrContext)

    const [searchParams, setSearchParams] = useSearchParams();
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpen(false)
	}

	const deleteOkr = () => {
		setIsDeleteDialogOpen(false)
	}

	let okr = state.okrList.find((o) => o.id === data.okrId)
	if (!okr) {
		okr = {
			title: '',
			keyResults: []
		}
	}

	const assignedTo = state.users.find(user => user.id === okr.assignedTo) || { name: ''}
  	return ( <>
		<div className='okr-node'>
			<div className='okr-node-avatar'>{getInitials(assignedTo.name)}</div>
			<div className='okr-node-controls'>
				<Button onClick={() => {
					dispatch({
						type: 'setOkrEditIsOpen',
						payload: {
							isEditOkrOpen: true,
							id: data.okrId
						}
					})

					dispatch({
						type: 'setEditNode',
						payload: {
							editNode: {...props}
						}
					})
				}}><EditIcon fontSize='small'></EditIcon></Button>
				<Button onClick={() => {setIsDeleteDialogOpen(true)}}><DeleteIcon fontSize='small'></DeleteIcon></Button>
			</div>
			<Handle
				type="target"
				className='okr-handle'
				// position="top"
				style={{ background: '#67c1bb' }}
				onConnect={(params) => {}}
				isConnectable={isConnectable}
			/>
			<div className="okr-title">{okr.title || ' '}</div>
			<div>
				{(okr.linkedOkrs || []).map(kr => {
					let linkedKr = state.okrList.find((o) => o.id === kr)
					if (!linkedKr) {
						return <></>
					}
					let status = state.okrStatusOptions.find(b => b.id === linkedKr.okrStatusId)
					return  <OkrTile
					key={`tile-${linkedKr.id}-${okr.id}`}
					onClick={(event) => {
						event.preventDefault()
						event.stopPropagation()
						// dispatch({
						//     type: 'setOkrEditIsOpen',
						//     payload: {
						//         isEditOkrOpen: true,
						//         id: okrItem.id
						//     }
						// })
						setSearchParams(`?edito=${linkedKr.boardId}`)
				}}>
					<OkrTileIcon status={stringToColor(status?.color)} statusLo={stringToColor(status?.lolightColor)} completion={linkedKr.completion}>{linkedKr.completion}%</OkrTileIcon>
					<span className='okr-node-title'>{linkedKr.title}</span>
				</OkrTile>
				}
				)}

			</div>
			<Handle
				type="source"
				position="bottom"
				id="b"
				style={{ top: 'auto', background: '#67c1bb' }}
				isConnectable={isConnectable}
				className='okr-handle'
			/>
			</div>
			<Dialog
				open={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Permanently delete {okr.boardFriendlyId} {okr.title}?
				</DialogTitle>
				<DialogContent>
				<DialogContentText id="alert-dialog-description">
					This action cannot be reversed.
				</DialogContentText>
				</DialogContent>
				<DialogActions>
				<Button onClick={closeDeleteDialog}>Cancel</Button>
				<Button onClick={deleteOkr} autoFocus>
					Delete
				</Button>
				</DialogActions>
			</Dialog>
    </>
  );
});