import React, { useContext, useEffect, useState } from "react";
import { OkrContext, OkrItem, OkrStatus } from "../contexts/okr-context";
import { API } from 'aws-amplify';
import { Link } from "react-router-dom";
import { listOkrItems, listBoards, listOkrStatuses, listUsers } from "../graphql/queries";
import InputLabel from '@mui/material/InputLabel';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuItem from '@mui/material/MenuItem';
import Slider from '@mui/material/Slider';
import {  useAuthenticator } from '@aws-amplify/ui-react';
import './goals.css'
import EditOkrDialog from "./edit-okr-dialog";

export default function Goals() {
    const {state, dispatch} = useContext(OkrContext)
    const { user } = useAuthenticator((context) => [context.user]);
    const [goals, setGoals] = useState<OkrItem[]>([])
    const [statusOptions, setStatusOptions] = useState<OkrStatus[]>([])

    const getCurrentUser = async () => {
      const usersResponse = await API.graphql({
        query: listUsers,
        variables: { limit: 10000 }
      }) as any

      dispatch({
        type: 'setUsers',
        payload: {
          users: usersResponse.data.listUsers.items
        }
      })
      const currentUser = usersResponse.data.listUsers.items.find((u: any) => u.email === user?.attributes?.email)
      dispatch({
        type: 'setCurrentUser',
        payload: {
          user: currentUser
        }
      })
    }

    useEffect(() => {
      if (! state.currentUser?.id) {
        getCurrentUser()
        return
      }
      const getObjectives = async () => {
        let okrItemResponse = await API.graphql({
          query: listOkrItems,
          variables: {
            // filter: {
            //   assignedTo: { eq: state.currentUser.id }
            // },
            limit: 10000
          }
        }) as any
        setGoals(okrItemResponse.data.listOkrItems.items)
      }
      const getBoards = async () => {
        const boards = await API.graphql({
          query: listBoards,
          variables: { limit: 10000 }
        }) as any

        dispatch({
          type: 'setBoards',
          payload: {
            boardList: boards.data.listBoards.items
          }
        })
      }

      const getOkrItemStatus = async () => {
        if (!state.currentBoard) { return }
        let okrItemResponse = await API.graphql({
          query: listOkrStatuses,
          variables: {
            limit: 10000
          }
        }) as any
        setStatusOptions(okrItemResponse.data.listOkrStatuses.items)
      }
      getOkrItemStatus()
      getBoards()
      getObjectives()
    }, [state.currentUser?.id])


    const setCompletion = (completion: number, okrId?: string) => {
      const newGoals = [...goals]
      const okr = newGoals.find(goal => goal.id === okrId)
      if (!okr) return;
      okr.completion = completion;
      setGoals(newGoals)
    }

    const setStatus = (status: string, okrId?: string) => {
      const newGoals = [...goals]
      const okr = newGoals.find(goal => goal.id === okrId)
      if (!okr) return;
      okr.okrStatusId = status;
      setGoals(newGoals)
    }

    const openEditOkr = (okr: OkrItem) => {
      dispatch({
        type: 'setCurrentBoard',
        payload: {
          board: state.boardList.find(board => board.id === okr.boardId)
        }
      })
      dispatch({
        type: 'setOkrItemList',
        payload: {
          okrList: goals
        }
      })
      dispatch({
        type: 'setOkrEditIsOpen',
        payload: {
          isEditOkrOpen: true,
          id: okr.id
        }
      })
    }

    return (<div className="board-container">
      {state.boardList.map(board => {
        return (
          <div className="board-group" key={board.id}>
            <div className="board-options">
              <div className="board-name">{board.title} - {board.friendlyId.toUpperCase()}</div>
              <div className="board-option-buttons" key={`options-${board.id}`}>
                <Link to={`/app/graph/${board.friendlyId}`}><AccountTreeOutlinedIcon></AccountTreeOutlinedIcon></Link>
                <Link to={`/app/calendar/${board.friendlyId}`}><CalendarMonthOutlinedIcon></CalendarMonthOutlinedIcon></Link>
                <Link to={`/app/settings/${board.friendlyId}`}><SettingsIcon></SettingsIcon></Link>
              </div>
            </div>
            <div className="goal-objectives">
              <div className="okr-table-header">
                <div className="okr-name okr-table-head">Objective</div>
                <div className="okr-completion okr-table-head">Completion</div>
                <div className="okr-status okr-table-head">Status</div>
                <div className="okr-end okr-table-head">End Date</div>
              </div>
              {goals.filter(goal => goal.boardId === board.id).map(okr => {

                const status: OkrStatus = statusOptions.find(option => option.id === okr.okrStatusId) || state.okrStatusOptions[0]
                if (!status) return <></>
                return (
                  <div className="okr-container" key={okr.id}>
                    <div className="okr-name okr-item" onClick={() => { openEditOkr(okr) }}>{okr.boardFriendlyId?.toUpperCase()} {okr.title}</div>
                    <div className="okr-completion okr-item">
                    <Slider
                        aria-label="Completion Percent"
                        value={okr.completion}
                        onChange={(event, value) => {
                            if (typeof value === 'number') {
                                setCompletion(value, okr.id)
                            }
                        }}
                        getAriaValueText={() => `${okr.completion}`}
                    />
                    </div>
                    <div className="okr-status okr-item">

                    <FormControl sx={{ m: 1, minWidth: 120 }}  variant="standard">
                            <Select
                                value={status.label}
                                onChange={(event: SelectChangeEvent) => {
                                    const s = statusOptions.find(o => o.label === event.target.value)
                                    if (s) { //todo: just make this simple
                                        setStatus(s.id, okr.id)
                                    }
                                }}
                            >
                                {statusOptions.sort((ba, bb) => ba.order - bb.order).map(option => {
                                    return (
                                        <MenuItem key={`select-status-${option.id}`} value={option.label}>{option.label}</MenuItem>
                                    )
                                })}
                            </Select>
                    </FormControl>
                    </div>
                    <div className="okr-end okr-item">{okr.endDate}</div>
                  </div>
                )
              })}
            </div>
          </div>
        )
      } )}
      {state.isEditOkrOpen && <EditOkrDialog></EditOkrDialog>}
    </div>)
}