import { useState, useContext, useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import moment from 'moment';
import dayjs, { Dayjs } from 'dayjs'; // stupid mui datepicker needs this
import { styled } from '@mui/system';
import Avatar from '@mui/material/Avatar';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuthenticator } from '@aws-amplify/ui-react';
import InputAdornment from '@mui/material/InputAdornment';
import Slider from '@mui/material/Slider';
import FormControl from '@mui/material/FormControl';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputLabel from '@mui/material/InputLabel';
import DialogContentText from '@mui/material/DialogContentText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { OkrContext, OkrItem, OkrStatus, Group, Comment } from '../contexts/okr-context';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SettingsIcon from '@mui/icons-material/Settings';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { listBoards, listOkrComments, listIntegrations } from '../graphql/queries';
import { createOkrItem,
    updateOkrItem,
    createOkrComment,
    updateOkrComment,
    updateOkrGraph,
    createGroup,
    createOkrTag,
    updateBoard,
    getJiraIssues } from '../graphql/mutations';
import { API } from 'aws-amplify';
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuid } from 'uuid';
import './edit-okr-dialog.css'
// import MondayCompute from './integrations/monday-compute'
import IntegrationPicker from './integrations/integration-picker';



// ***************************** STUFF TO MOVE ************************** //

const getInitials = (name: string) => {
    const initials = name.split(' ')
    if (initials.length > 1) {
        return `${initials[0][0]}${initials[1][0]}`.toUpperCase()
    } else {
        return name[0].toUpperCase()
    }
}

interface OkrTileIconProps {
    status: string;
    completion: number;
    statusLo: string;
}

const OkrTileIcon = styled('p')((props: OkrTileIconProps) => ({
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: `conic-gradient(${props.status} ${props.completion}%, ${props.statusLo} ${props.completion}%) border-box`,
    margin: 4
}))

const generateNewOkrItem = (data: any) => {
    return {
        tempId: uuid(),
        linkedOkrs: [],
        title: 'Key Result',
        status: {label: 'Green', color: 'green', lolightColor: 'rgba(200,255,200,1)' } as OkrStatus,
        endDate: data.endDate,
        createdBy: data.user,
        assignedTo: data.user,
        groups: [],
        tags: [],
        completion: 0,
        isCompletionCalculated: false,
        boardId: 'AA-' //TODO generate
      }
}


const stringToColor = (string: string) => {
	const color = JSON.parse(string)
	return `rgba(${color.r},${color.g},${color.b},${color.a})`
}

// ***************************** END STUFF TO MOVE ************************** //

interface EditOkrDialogProps {
    nodes?: any,
    setNodes?: any
}

export default function EditOkrDialog(props: EditOkrDialogProps) {
  const [objective, setObjective] = useState('');
  const [keyResults, setKeyResults] = useState<Array<OkrItem>>([])
  const {state, dispatch} = useContext(OkrContext)
  const navigate = useNavigate();
  const [editingOkr, setEditingOkr] = useState<OkrItem>({} as OkrItem)
  const [isCompletionCalculated, setIsCompletionCalculated] = useState(false)
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(true)
  const [completion, setCompletion] = useState(0)
  const [expandDetails, setExpandDetails] = useState(true)
  const [groups, setGroups] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [groupsInput, setGroupsInput] = useState('')
  const [tagsInput, setTagsInput] = useState('')
  const [assignedTo, setAssignedTo] = useState('')
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [comment, setComment] = useState('')
  const [comments, setComments] = useState<Comment[]>([])
  const location = useLocation();

  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"))
  const [status, setStatus] = useState<OkrStatus>(state.okrStatusOptions[0] || {})
  const { user } = useAuthenticator((context) => [context.user]);

  const saveOkrItem = async (okrItem: OkrItem) => {
    const input = { //type is OkrItemModel but can't use without an id?
        title: okrItem.title,
        okrStatusId: okrItem.status.id,
        linkedOkrs: okrItem.linkedOkrs || [],
        completion: okrItem.completion || 0,
        isCompletionCalculated: okrItem.isCompletionCalculated || false,
        endDate: moment(okrItem.endDate.toString()).format("YYYY-MM-DD") || moment(state.currentBoard?.endDate).format("YYYY-MM-DD") || moment().format("YYYY-MM-DD"),
        boardId: state.currentBoard?.id
    } as any
    if (okrItem.id) {
        input.id = okrItem.id
    }
    if (okrItem.createdBy) {
        input.createdBy = okrItem.createdBy
    }
    if (okrItem.assignedTo) {
        input.assignedTo = okrItem.assignedTo
    }
    if (okrItem.groups) {
        input.groups = okrItem.groups
    }
    if (okrItem.boardId) {
        input.boardId = okrItem.boardId
    }
    if (okrItem.boardId) {
        input.boardId = okrItem.boardId
    }
    if (okrItem.id) {
        input.boardFriendlyId = okrItem.boardFriendlyId
    } else {
        const boardRequest = await API.graphql({
            query: listBoards,
            variables: {
                filter: {
                    id: { eq: state.currentBoard?.id }
                },
            limit: 10000
            }
        }) as any
        let nextId = boardRequest.data.listBoards.items[0].nextFriendlyId
        if (!nextId) { // returns from the DB as NULL when it should be 0 TODO fix
            nextId = 0
        }
        input.boardFriendlyId = `${state.currentBoard?.friendlyId}-${nextId}`
        await API.graphql({
            query: updateBoard,
            variables: {
                input: {
                    id: state.currentBoard?.id,
                    nextFriendlyId: nextId + 1
                }
            }
        })
    }

    const query = okrItem.id ? updateOkrItem : createOkrItem
    if (!okrItem.id) {
        input.groupAccess = user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
    }
    const okrSave = await API.graphql({
        query,
        variables: {
            input
        }
    }) as any
    return okrSave.data[okrItem.id ? 'updateOkrItem' : 'createOkrItem']
  }

  const saveComment = async () => {
    const commentResponse = await API.graphql({
        query: createOkrComment,
        variables: {
            input: {
                text: comment,
                createdBy: state.currentUser.id,
                okrId: editingOkr.id,
                groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
            }
        }
    }) as any
    setComments(comments.concat(commentResponse.data.createOkrComment))
    setComment('')
  }

  const saveCurrentOkrItem = async () => {
    const okrData = {
        title: objective,
        endDate,
        status,
        completion,
        isCompletionCalculated,
        assignedTo: assignedTo,
        linkedOkrs: keyResults.map(kr => kr.id).filter(kr => kr !== undefined)
    } as OkrItem

    if (editingOkr.id) {
    okrData.id = editingOkr.id
    }
    const okr = await saveOkrItem(okrData)

    dispatch({
        type: 'addOkrItem',
        payload: {
            okrItem: okr
        }
    })
    dispatch({
        type: 'setEditOkrId',
        payload: { id: okr.id }
    })
    return okr
  }

  const saveGroup = async (saveGroups: string[]) => {
    setGroups(saveGroups)
    if (!editingOkr.id) {
        const newOkr = await saveCurrentOkrItem()
        editingOkr.id = newOkr.id
    }
    const newGroups = []
    for (const saveGroup of saveGroups) {
        const groupId = state.groupList.find(g => g.title === saveGroup)
        if (!groupId) {
            const newGroup = await API.graphql({
                query: createGroup,
                variables: {
                    input: {
                        title: saveGroup,
                        accountId: state.account.id,
                        groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
                    }
                }
            }) as any
            dispatch({
                type: 'addGroup',
                payload: {
                    group: newGroup.data.createGroup
                }
            })
        } else {
            newGroups.push(groupId.id)
        }
    }
    const newOkr = await API.graphql({
        query: updateOkrItem,
        variables: {
            input: {
                id: editingOkr.id,
                groups: newGroups
            }
        }
    })
  }


  const saveTag = async (saveTags: string[]) => {
    setTags(saveTags)
    const newTags = []
    if (!editingOkr.id) {
        const newOkr = await saveCurrentOkrItem()
        editingOkr.id = newOkr.id
    }
    for (const saveTag of saveTags) {
        const tagId = state.tagList.find(t => t.text === saveTag)
        if (!tagId) {
            const newTag = await API.graphql({
                query: createOkrTag,
                variables: {
                    input: {
                        text: saveTag,
                        accountId: state.account.id,
                        groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
                    }
                }
            }) as any
            dispatch({
                type: 'addTag',
                payload: {
                    tag: newTag.data.createOkrTag
                }
            })
        } else {
            newTags.push(tagId.id)
        }
    }
    const newOkr = await API.graphql({
        query: updateOkrItem,
        variables: {
            input: {
                id: editingOkr.id,
                tags: newTags
            }
        }
    })
  }
  useEffect(() => {
    if (!objective && !tags.length && !groups.length && !assignedTo && !completion && !keyResults.length) {
        return
    }
    setUnsavedChanges(true)
  }, [objective, tags, groups, assignedTo, endDate, status, completion, keyResults])

  useEffect(() => {
    const okr = state.okrList.find(okr => okr.id === state.editOkrId)
    const status = state.okrStatusOptions.find(bb => bb.id === okr?.okrStatusId) || state.okrStatusOptions.sort((ba, bb) => ba.order - bb.order)[0]
    setStatus(status)
    if (okr) {
        setEditingOkr(okr)
        setObjective(okr.title)
        setEndDate(okr.endDate)
        // setStatus(status)
        setAssignedTo(okr.assignedTo)
        setCompletion(okr.completion)
        setIsCompletionCalculated(okr.isCompletionCalculated)
        setGroups(okr.groups?.map(group => state.groupList.find(g => g.id === group)?.title) as string[] || [])
        setTags(okr.tags?.map(tag => state.tagList.find(t => t.id === tag)?.text) as string[] || [])
        if (okr.linkedOkrs) {
            const krs = okr.linkedOkrs.map(oid => {
                const kr = state.okrList.find(so => so.id === oid)
                return kr || {} as OkrItem
            })
            setKeyResults(krs)
        }
    }
    setUnsavedChanges(false)

    const getComments = async () => {
        if (!state.editOkrId) { return }
        const commentsResponse = await API.graphql({
            query: listOkrComments,
            variables: {
                filter: {
                    okrId: { eq: state.editOkrId }
                },
                limit: 10000
            }
        }) as any
        dispatch({
            type: 'setOkrComments',
            payload: {
                comments: commentsResponse.data.listOkrComments.items
            }
        })
        setComments(commentsResponse.data.listOkrComments.items.sort((a: Comment, b: Comment) => {
            if (moment(a.createdAt).isAfter(moment(b.createdAt))) {
                return 1
            } else if (moment(a.createdAt).isBefore(moment(b.createdAt))){
                return -1
            }
            return 0
        }))
    }

    const getIntegrations = async() => {
        const integrationData = await API.graphql({
            query: listIntegrations,
            variables: {
                limit: 10000
            }
        }) as any
        dispatch({
          type: 'setIntegrationList',
          payload: {
            integrationList: integrationData.data.listIntegrations.items
          }
        })
    }

    getIntegrations()
    getComments()
  }, [state.editOkrId])

  const onClose = () => {

    dispatch({
        type: 'setOkrEditIsOpen',
        payload: {
            isEditOkrOpen: false,
            editOkrId: false
        }
      })
  }

  useEffect(() => {
    if (state.editOkrDialogDate) {
        setEndDate(state.editOkrDialogDate)
    }
  }, [state.editOkrDialogDate])

  const tagOptions = state.tagList?.map((option) => option.text)
  const groupOptions = state.groupList?.map((option) => option.title)
  return (
    <Dialog open={state.isEditOkrOpen} fullWidth={true} maxWidth='lg'>
        <DialogTitle sx={{ m: 0, p: 2 }}>
            {editingOkr.boardFriendlyId ? editingOkr.boardFriendlyId.toUpperCase() : 'Create New OKR'}
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
                <CloseIcon />
            </IconButton>
            <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 36,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
            >
                <DeleteIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: '80vh' }}>
            <div className='edit-dialog-content' style={{display: 'flex', flexDirection: 'row'}}>
                <div className='edit-dialog-left' style={{width: '60%'}}>
                    <DialogContentText style={{margin: 8}}>
                        Express your goal with realistic a realistic and aggresive objective that describes what you want to accomplish, and key results describing measureable outcomes.
                    </DialogContentText>
                    <Autocomplete
                        id="free-solo-demo"
                        freeSolo
                        options={state.okrList.map((okr: OkrItem) => `${okr.title}`)}
                        inputValue={objective}
                        onInputChange={(event, value) => setObjective(value)}
                        renderInput={(params) => <TextField
                            required
                            label="Objective"
                            {...params}
                            style={{padding: 8, width: '100%'}}
                        />}
                    />
                    <div id='create-input-container' style={{display: 'flex', flexDirection: 'column'}}>
                    {keyResults.map(krid => {
                        let kr = state.okrList.find(okr => okr.id == krid.id) ||
                            keyResults.find(krs => krs.tempId === krid.tempId)
                        if (!kr || (!kr.id && !kr.tempId)) {
                            return <></>
                        }
                        const krStatus = state.okrStatusOptions.find(bo => bo.id === kr?.okrStatusId)
                            || state.okrStatusOptions[0]
                    return (<TextField
                        key={`edit-okr-kr-${kr.id ? kr.id : kr.tempId}`}
                        required
                        id="outlined-required"
                        label="Key Result"
                        value={kr.title}
                        onChange={(event) => {
                        let toUpdate = keyResults.find(k => (k.id ? k.id === kr?.id : k.tempId === kr?.tempId))
                        if (toUpdate) {
                            toUpdate.title = event.target.value
                            let newKrs = [...keyResults]
                            newKrs[keyResults.indexOf(toUpdate)] = toUpdate
                            setKeyResults(newKrs)
                        }
                        }}
                        style={{padding: 8}}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position='start'>
                                    <OkrTileIcon status={stringToColor(krStatus.color)} statusLo={stringToColor(krStatus.lolightColor)} completion={kr.completion}></OkrTileIcon>
                                </InputAdornment>
                            ),
                            endAdornment: !kr.id ? (<InputAdornment position='end'>
                                    <Button onClick={async () => {
                                        if (!editingOkr.id) {
                                            const okrData = {
                                                title: objective,
                                                endDate,
                                                status,
                                                completion,
                                                isCompletionCalculated,
                                                linkedOkrs: keyResults.map(kr => kr.id).filter(kr => kr !== undefined)
                                              } as OkrItem

                                            if (editingOkr.id) {
                                            okrData.id = editingOkr.id
                                            }

                                            const okrItem = await saveOkrItem(okrData)
                                            editingOkr.id = okrItem.id //bad mutation of state

                                            dispatch({
                                                type: 'addOkrItem',
                                                payload: {
                                                    okrItem
                                                }
                                            })
                                            dispatch({
                                                type: 'setEditOkrId',
                                                payload: { id: editingOkr.id }
                                            })
                                        }


                                        const okrData = {
                                            title: kr?.title,
                                            endDate,
                                            status,
                                            completion: 0,
                                            isCompletionCalculated: false
                                        } as OkrItem
                                        //TODO add graph nodes on save
                                        const okrItem = await saveOkrItem(okrData)
//                                         if (props.setNodes) {
//                                             props.setNodes((nodes: any) => { //Add the kr to the graph
//                                                 const editNode = {...state.flow.editNode}
//                                                 let enode = nodes.find((n: any) => n.id === editNode.id)
//                                                 let updateNodes = props.nodes
//                                                 const newNode = {
//                                                     id: okrItem.id,
//                                                     type: 'okr',
//                                                     position: { x: editNode.xPos - 375 + (220 * keyResults.length), y: editNode.yPos + 100 + enode.style.height },
//                                                     style: {height: 42},
//                                                     data: { label: `Node ${kr?.id}`, setNodes: props.setNodes, okrId: okrItem.id },
//                                                 };
//                                                 updateNodes = nodes.concat(newNode)
//                                                 return updateNodes
//                                             })
//                                         }

                                        dispatch({
                                            type: 'addOkrItem',
                                            payload: {
                                                okrItem: {
                                                    ...okrItem
                                                },
                                            }
                                        })
                                        const newKrs = keyResults.map(k => {
                                            if (k === kr) { //TODO probably add more saved props here??
                                                k.id = okrItem.id
                                                k.boardFriendlyId = okrItem.boardFriendlyId
                                            }
                                            return k
                                        })

                                        setKeyResults(newKrs)

                                        const linkData = {
                                            title: objective,
                                            endDate,
                                            status,
                                            completion,
                                            isCompletionCalculated,
                                            id: editingOkr.id,
                                            linkedOkrs: keyResults.map(kr => kr.id).concat(okrItem.id).filter(kr => kr != undefined) || [okrItem.id]
                                        } as OkrItem

                                        await saveOkrItem(linkData)
                                        //TODO: save then link (temp-id breaks)
                                        dispatch({
                                            type: 'linkKrToOkr',
                                            payload: {
                                                id: editingOkr.id,
                                                okrToLinkId: kr?.id
                                            }
                                        })
                                    }}>Save</Button>
                                    <DeleteIcon color="primary" style={{cursor: 'pointer'}}></DeleteIcon>
                                </InputAdornment>) : (
                                <InputAdornment position="end">
                                    <Link to={`?edito=${kr.boardFriendlyId}`} style={{color: '#1976d2', textDecoration: 'none', margin: '0 4px'}}>{kr.boardFriendlyId}</Link>
                                    <LinkOffIcon color="primary" style={{cursor: 'pointer'}}></LinkOffIcon>
                                    <DeleteIcon color="primary" style={{cursor: 'pointer'}}></DeleteIcon>
                                </InputAdornment>
                            )
                        }}
                    />)}
                    )}
                    {keyResults.length < 10 &&
                    <Button variant="outlined" startIcon={<AddCircleIcon />}
                    style={{margin: 8}}
                    onClick={() => { //TODO use something other than user.attibutes.sub (where's the id?)
                        setKeyResults(keyResults.concat( generateNewOkrItem({endDate, user: user?.attributes?.sub || 'N/A'}) ))
                    }}>
                    Add Key Result
                    </Button>}
                    <TextField
                        style={{margin: '8px'}}
                        id="outlined-multiline-static"
                        label="Comments"
                        multiline
                        rows={4}
                        value={comment}
                        onChange={(event) => { setComment(event.target.value)}}
                    />
                    { comment !== '' && <div style={{display: 'flex', flexDirection: 'row-reverse', margin: '0 8px'}}>
                        <Button size='small' variant='contained' onClick={saveComment}>Save</Button>
                        <Button size='small' onClick={() => { setComment('') }}>Cancel</Button>
                    </div> }
                    {comments.map(c => {
                        const author = state.users.find(u => u.id === c.createdBy) || { name: 'N/A' }
                        return (<div className='okr-comment'>
                            <div className='okr-comment-avatar'>
                                <Avatar alt={getInitials(author.name || 'N A')} src="/static/images/avatar/2.jpg" />
                            </div>
                            <div className='okr-comment-right'>
                                <div className='okr-comment-right-top'>
                                    <div className='okr-comment-author'>{author.name}</div>
                                    <div className='okr-comment-time'>{moment(c.createdAt).fromNow()}</div>
                                </div>
                                <div className='okr-comment-text'>
                                    {c.text}
                                </div>
                            </div>
                        </div>)
                    })}
                    </div>
                </div>
                <div className='edit-dialog-right' style={{width: "40%", margin: 8}}>
                <Accordion expanded={expandDetails} onChange={() => setExpandDetails(!expandDetails)}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="edit-dialog-details"
                    id="edit-dialog-details"
                    >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                        Details
                    </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <div id='create-input-container' style={{display: 'flex', flexDirection: 'column'}}>
                        <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="status-name-label">Status</InputLabel>
                            <Select
                                labelId="status-name-label"
                                id="status-name"
                                label="Status"
                                value={status.label}
                                onChange={(event) => {
                                    const s = state.okrStatusOptions.find(o => o.label === event.target.value)
                                    if (s) { //todo: just make this simple
                                        setStatus(s)
                                    }
                                }}
                            >
                                {state.okrStatusOptions.sort((ba, bb) => ba.order - bb.order).map(option => {
                                    return (
                                        <MenuItem key={`select-status-${option.id}`} value={option.label}>{option.label}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', margin: '0 12px'}}>
                            <div>Completion</div>
                            <FormControl sx={{ m: 1, minWidth: 220, padding: '0 8px', margin: '36px 8px 8px 8px' }}>
                                <Slider
                                    aria-label="Completion Percent"
                                    value={completion}
                                    onChange={(event, value) => {
                                        if (typeof value === 'number') {
                                            setCompletion(value)
                                        }
                                    }}
                                    getAriaValueText={() => `${editingOkr.completion}`}
                                    valueLabelDisplay="on"
                                />
                            </FormControl>
                        </div>
                        <FormControl fullWidth style={{margin: '8px 0px'}}>
                        <InputLabel id="assign-to">Assigned To</InputLabel>
                        <Select
                            labelId="assign-to"
                            id="assign-to-select"
                            value={assignedTo}
                            label="Assigned To"
                            onChange={(event) => { setAssignedTo(event.target.value) }}
                        >
                            {state.users.map(user =>
                                <MenuItem value={user.id} key={user.id}>{user.name}</MenuItem>)
                            }
                        </Select>
                        </FormControl>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'DatePicker']}>
                                <DatePicker
                                label="End Date"
                                value={dayjs(endDate)}
                                onChange={(newValue) => {
                                    setEndDate(newValue as unknown as string)
                                }}
                                />
                            </DemoContainer>
                            </LocalizationProvider>
                        </div>
                        <Autocomplete
                            id="autocomplete-group"
                            multiple
                            freeSolo
                            value={groups}
                            options={groupOptions}
                            onChange={(event, val) => { saveGroup(val as string[]) }}
                            inputValue={groupsInput}
                            onInputChange={(event, value) => setGroupsInput(value)}
                            renderInput={(params) => <TextField
                                label="Groups"
                                {...params}
                                style={{margin: "8px 0", width: '100%'}}
                            />}
                        />

                        <Autocomplete
                            id="autocomplete-tags"
                            multiple
                            freeSolo
                            options={tagOptions}
                            inputValue={tagsInput}
                            value={tags}
                            onChange={(event, val) => { saveTag(val as string[])}}
                            onInputChange={(event, value) => setTagsInput(value)}
                            // todo renderTags= for colors
                            renderInput={(params) => <TextField
                                label="Tags"
                                {...params}
                                style={{margin: "8px 0", width: '100%'}}
                            />}
                        />
                        <p className='autocomplete-hint'>Press enter to save</p>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion expanded={showAdvancedOptions} onChange={() => setShowAdvancedOptions(!showAdvancedOptions)}>
                        <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="edit-dialog-details"
                        id="edit-dialog-details"
                        >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Advanced
                        </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <IntegrationPicker></IntegrationPicker>
                        </AccordionDetails>
                    </Accordion>
                    <div>
                        <Link to={`/app/settings/${state.currentBoard?.friendlyId || location.pathname.split('/')[3]}`}>
                            <Button style={{float: 'right', color: 'rgba(50,50,50, .5)'}}>
                                <SettingsIcon></SettingsIcon>Settings</Button>
                        </Link>
                    </div>
                </div>
            </div>


        </DialogContent>

        <DialogActions style={{margin: 8}}>
            <Button onClick={() => {
              onClose()
            }}>Cancel</Button>
            <Button variant="contained" onClick={async () => {

              const editNode = {...state.flow.editNode}
              const okrData = {
                title: objective,
                endDate,
                status,
                completion,
                isCompletionCalculated,
                assignedTo,
                linkedOkrs: keyResults.map(kr => kr.id).filter(kr => kr !== undefined)
              } as OkrItem

              if (editingOkr.id) {
                okrData.id = editingOkr.id
              }

              const okrItem = await saveOkrItem(okrData)
              dispatch({
                type: 'editOkrItem',
                payload: okrItem
              })
              let updateNodes = props.nodes

              if (props.nodes) {
                editNode.data = {
                    okrId: okrItem.id
                }
                if (!editNode.style) {
                    editNode.style = { width: '260px'}
                }
                if (!editNode.position && editNode.xPos) {
                    editNode.position = {
                        x: editNode.xPos,
                        y: editNode.yPos
                    }
                }
                let krHeight = 0
                keyResults.forEach(kr => {
                    krHeight += 12 * (Math.ceil(kr.title.length / 36))
                })
                // editNode.style.height = 36 * (keyResults.length + 1) + (Math.ceil(objective.length / 36) * 12)
                editNode.style.height = 16 + 12 * (keyResults.length + 1) + krHeight + (Math.ceil(objective.length / 36) * 12)
                props.setNodes((nodes: any) => {
                    let enode = nodes.find((n: any) => n.id === editNode.id)
                    if (!enode) {
                        updateNodes = nodes.concat(editNode)
                    } else {
                        nodes[nodes.indexOf(enode)] = editNode
                        updateNodes = nodes
                    }
                    return updateNodes
                })
                const query = updateOkrGraph
                const input = {
                    boardId: state.currentBoard?.id,
                    edges: JSON.stringify(state.flow.edges),
                    nodes: JSON.stringify(updateNodes)
                } as any
                input.id = state.flow.id
                const graph = await API.graphql({
                        query,
                        variables: {
                            input
                        }
                }) as any
                window.setTimeout(() => { //TODO figure out why this update sucks
                    if (props.setNodes) { props.setNodes((nds: any) => [...nds]) }
                }, 1000)
              }

              dispatch({
                type: 'setOkrEditIsOpen',
                payload: {
                    isEditOkrOpen: false,
                    editOkrId: ''
                }
              })
              setObjective('')
              setKeyResults([])
            }}>Save</Button>
            </DialogActions>
    </Dialog>
  );
}