import * as React from 'react';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

const footers = [
    {
      title: 'Company',
      description: ['Team', 'History', 'Contact us'],
    },
    {
      title: 'Developers',
      description: [
        'API Reference',
        'Documentation',
        'API Status',
        'Developer stuff',
        'Another one',
      ],
    },
    {
      title: 'Resources',
      description: ['Support Center', 'Templates', 'Blog', 'Contact Sales'],
    },
    {
      title: 'Legal',
      description: ['Privacy policy', 'Terms of use'],
    },
];


function Copyright(props: any) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" to="https://www.okrcalendar.com/">
          OKR Calendar
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }

export default function Footer() {

    return <Container
    maxWidth="md"
    component="footer"
    style={{position: 'relative', bottom: '0', display: 'flex', flexDirection: 'column'}}
    sx={{
      borderTop: (theme) => `1px solid ${theme.palette.divider}`,
      mt: 8,
      py: [3, 6],
    }}
  >
    <div></div>
    {/* <Grid container spacing={4} justifyContent="space-evenly">
      {footers.map((footer) => (
        <Grid item xs={6} sm={3} key={footer.title}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            {footer.title}
          </Typography>
          <ul>
            {footer.description.map((item) => (
              <li key={item}>
                <Link to="/nav/contact">
                  {item}
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
      ))}
    </Grid> */}
    <Copyright sx={{ mt: 5 }} />
  </Container>
}