/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const saveToken = /* GraphQL */ `
  mutation SaveToken($authCode: String) {
    saveToken(authCode: $authCode)
  }
`;
export const getJiraIssues = /* GraphQL */ `
  mutation GetJiraIssues($integrationId: String) {
    getJiraIssues(integrationId: $integrationId)
  }
`;
export const getJiraStatuses = /* GraphQL */ `
  mutation GetJiraStatuses($integrationId: String) {
    getJiraStatuses(integrationId: $integrationId)
  }
`;
export const getJiraProjects = /* GraphQL */ `
  mutation GetJiraProjects($integrationId: String) {
    getJiraProjects(integrationId: $integrationId)
  }
`;
export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      name
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      name
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      name
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      accountId
      name
      email
      cognitoId
      avatarColor
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      accountId
      name
      email
      cognitoId
      avatarColor
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      accountId
      name
      email
      cognitoId
      avatarColor
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createKeyResult = /* GraphQL */ `
  mutation CreateKeyResult(
    $input: CreateKeyResultInput!
    $condition: ModelKeyResultConditionInput
  ) {
    createKeyResult(input: $input, condition: $condition) {
      id
      parent_id
      boardId
      accountId
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateKeyResult = /* GraphQL */ `
  mutation UpdateKeyResult(
    $input: UpdateKeyResultInput!
    $condition: ModelKeyResultConditionInput
  ) {
    updateKeyResult(input: $input, condition: $condition) {
      id
      parent_id
      boardId
      accountId
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteKeyResult = /* GraphQL */ `
  mutation DeleteKeyResult(
    $input: DeleteKeyResultInput!
    $condition: ModelKeyResultConditionInput
  ) {
    deleteKeyResult(input: $input, condition: $condition) {
      id
      parent_id
      boardId
      accountId
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createFlowNode = /* GraphQL */ `
  mutation CreateFlowNode(
    $input: CreateFlowNodeInput!
    $condition: ModelFlowNodeConditionInput
  ) {
    createFlowNode(input: $input, condition: $condition) {
      id
      position
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateFlowNode = /* GraphQL */ `
  mutation UpdateFlowNode(
    $input: UpdateFlowNodeInput!
    $condition: ModelFlowNodeConditionInput
  ) {
    updateFlowNode(input: $input, condition: $condition) {
      id
      position
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteFlowNode = /* GraphQL */ `
  mutation DeleteFlowNode(
    $input: DeleteFlowNodeInput!
    $condition: ModelFlowNodeConditionInput
  ) {
    deleteFlowNode(input: $input, condition: $condition) {
      id
      position
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      title
      color
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      title
      color
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      title
      color
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOkrStatus = /* GraphQL */ `
  mutation CreateOkrStatus(
    $input: CreateOkrStatusInput!
    $condition: ModelOkrStatusConditionInput
  ) {
    createOkrStatus(input: $input, condition: $condition) {
      id
      label
      color
      lolightColor
      order
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOkrStatus = /* GraphQL */ `
  mutation UpdateOkrStatus(
    $input: UpdateOkrStatusInput!
    $condition: ModelOkrStatusConditionInput
  ) {
    updateOkrStatus(input: $input, condition: $condition) {
      id
      label
      color
      lolightColor
      order
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOkrStatus = /* GraphQL */ `
  mutation DeleteOkrStatus(
    $input: DeleteOkrStatusInput!
    $condition: ModelOkrStatusConditionInput
  ) {
    deleteOkrStatus(input: $input, condition: $condition) {
      id
      label
      color
      lolightColor
      order
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOkrItem = /* GraphQL */ `
  mutation CreateOkrItem(
    $input: CreateOkrItemInput!
    $condition: ModelOkrItemConditionInput
  ) {
    createOkrItem(input: $input, condition: $condition) {
      id
      title
      okrStatusId
      linkedOkrs
      completion
      isCompletionCalculated
      endDate
      createdBy
      assignedTo
      groups
      tags
      boardId
      boardFriendlyId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOkrItem = /* GraphQL */ `
  mutation UpdateOkrItem(
    $input: UpdateOkrItemInput!
    $condition: ModelOkrItemConditionInput
  ) {
    updateOkrItem(input: $input, condition: $condition) {
      id
      title
      okrStatusId
      linkedOkrs
      completion
      isCompletionCalculated
      endDate
      createdBy
      assignedTo
      groups
      tags
      boardId
      boardFriendlyId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOkrItem = /* GraphQL */ `
  mutation DeleteOkrItem(
    $input: DeleteOkrItemInput!
    $condition: ModelOkrItemConditionInput
  ) {
    deleteOkrItem(input: $input, condition: $condition) {
      id
      title
      okrStatusId
      linkedOkrs
      completion
      isCompletionCalculated
      endDate
      createdBy
      assignedTo
      groups
      tags
      boardId
      boardFriendlyId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      id
      title
      accountId
      friendlyId
      endDate
      nextFriendlyId
      status
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      title
      accountId
      friendlyId
      endDate
      nextFriendlyId
      status
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      title
      accountId
      friendlyId
      endDate
      nextFriendlyId
      status
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createIntegration = /* GraphQL */ `
  mutation CreateIntegration(
    $input: CreateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    createIntegration(input: $input, condition: $condition) {
      id
      accountId
      type
      title
      config
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateIntegration = /* GraphQL */ `
  mutation UpdateIntegration(
    $input: UpdateIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    updateIntegration(input: $input, condition: $condition) {
      id
      accountId
      type
      title
      config
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteIntegration = /* GraphQL */ `
  mutation DeleteIntegration(
    $input: DeleteIntegrationInput!
    $condition: ModelIntegrationConditionInput
  ) {
    deleteIntegration(input: $input, condition: $condition) {
      id
      accountId
      type
      title
      config
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOkrGraph = /* GraphQL */ `
  mutation CreateOkrGraph(
    $input: CreateOkrGraphInput!
    $condition: ModelOkrGraphConditionInput
  ) {
    createOkrGraph(input: $input, condition: $condition) {
      id
      boardId
      edges
      nodes
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOkrGraph = /* GraphQL */ `
  mutation UpdateOkrGraph(
    $input: UpdateOkrGraphInput!
    $condition: ModelOkrGraphConditionInput
  ) {
    updateOkrGraph(input: $input, condition: $condition) {
      id
      boardId
      edges
      nodes
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOkrGraph = /* GraphQL */ `
  mutation DeleteOkrGraph(
    $input: DeleteOkrGraphInput!
    $condition: ModelOkrGraphConditionInput
  ) {
    deleteOkrGraph(input: $input, condition: $condition) {
      id
      boardId
      edges
      nodes
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOkrComment = /* GraphQL */ `
  mutation CreateOkrComment(
    $input: CreateOkrCommentInput!
    $condition: ModelOkrCommentConditionInput
  ) {
    createOkrComment(input: $input, condition: $condition) {
      id
      okrId
      createdBy
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOkrComment = /* GraphQL */ `
  mutation UpdateOkrComment(
    $input: UpdateOkrCommentInput!
    $condition: ModelOkrCommentConditionInput
  ) {
    updateOkrComment(input: $input, condition: $condition) {
      id
      okrId
      createdBy
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOkrComment = /* GraphQL */ `
  mutation DeleteOkrComment(
    $input: DeleteOkrCommentInput!
    $condition: ModelOkrCommentConditionInput
  ) {
    deleteOkrComment(input: $input, condition: $condition) {
      id
      okrId
      createdBy
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createOkrTag = /* GraphQL */ `
  mutation CreateOkrTag(
    $input: CreateOkrTagInput!
    $condition: ModelOkrTagConditionInput
  ) {
    createOkrTag(input: $input, condition: $condition) {
      id
      accountId
      text
      color
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateOkrTag = /* GraphQL */ `
  mutation UpdateOkrTag(
    $input: UpdateOkrTagInput!
    $condition: ModelOkrTagConditionInput
  ) {
    updateOkrTag(input: $input, condition: $condition) {
      id
      accountId
      text
      color
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteOkrTag = /* GraphQL */ `
  mutation DeleteOkrTag(
    $input: DeleteOkrTagInput!
    $condition: ModelOkrTagConditionInput
  ) {
    deleteOkrTag(input: $input, condition: $condition) {
      id
      accountId
      text
      color
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCandidate = /* GraphQL */ `
  mutation UpdateCandidate(
    $input: UpdateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    updateCandidate(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const deleteCandidate = /* GraphQL */ `
  mutation DeleteCandidate(
    $input: DeleteCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    deleteCandidate(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const createUserTour = /* GraphQL */ `
  mutation CreateUserTour(
    $input: CreateUserTourInput!
    $condition: ModelUserTourConditionInput
  ) {
    createUserTour(input: $input, condition: $condition) {
      tourSteps
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserTour = /* GraphQL */ `
  mutation UpdateUserTour(
    $input: UpdateUserTourInput!
    $condition: ModelUserTourConditionInput
  ) {
    updateUserTour(input: $input, condition: $condition) {
      tourSteps
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserTour = /* GraphQL */ `
  mutation DeleteUserTour(
    $input: DeleteUserTourInput!
    $condition: ModelUserTourConditionInput
  ) {
    deleteUserTour(input: $input, condition: $condition) {
      tourSteps
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCandidate = /* GraphQL */ `
  mutation CreateCandidate(
    $input: CreateCandidateInput!
    $condition: ModelCandidateConditionInput
  ) {
    createCandidate(input: $input, condition: $condition) {
      id
      name
      email
      createdAt
      updatedAt
    }
  }
`;
