import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { API } from 'aws-amplify';
import { OkrContext } from "../../contexts/okr-context"
import {  useAuthenticator } from '@aws-amplify/ui-react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import React from "react";
import { createIntegration, updateIntegration } from "../../graphql/mutations";
import { listIntegrations } from "../../graphql/queries";
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, TextField } from "@mui/material";
import { Link } from "react-router-dom";


interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'integration', label: 'Title', minWidth: 170 },
  { id: 'type', label: 'Type', minWidth: 170, align: 'right' },
  { id: 'action', label: 'Actions', minWidth: 170, align: 'right' },
];

export default function IntegrationsPage() {

  const {state, dispatch} = useContext(OkrContext)
  const [page, setPage] = useState(0);
  const [isAddIntegrationOpen, setIsAddIntegrationOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { user } = useAuthenticator((context) => [context.user]);


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchIntegrations = async () => {
      const integrationsList = await API.graphql({
        query: listIntegrations,
        variables: {
          limit: 10000
        }
      }) as any
      dispatch({
        type: 'setIntegrationList',
        payload: {
          integrationList: integrationsList.data.listIntegrations.items
        }
      })
    }
    fetchIntegrations()
  }, [])


  if (state.isDemo) {
    return <div style={{
      background: 'lightblue',
      borderRadius: '4px',
      padding: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Link to="/nav/contact">Integrations are not enabled for your account. Click <span style={{textDecoration: 'underline'}}>HERE</span> to upgrade.</Link>
    </div>
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <AddIntegrationDialog open={isAddIntegrationOpen} closeFunction={setIsAddIntegrationOpen}></AddIntegrationDialog>
      <Button onClick={() => {
        setIsAddIntegrationOpen(true)
      }}>Create New</Button>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.integrationList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((integration) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={integration.id}>

                          <TableCell> {integration.title}
                          </TableCell>
                          <TableCell style={{textTransform: 'capitalize'}} align="right">{integration.type}</TableCell>
                          <TableCell align="right">
                            <Link to={`/app/integrations/jira/${integration.id}/${integration.title}`}>
                              <SettingsIcon></SettingsIcon>
                            </Link>
                          </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.integrationList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}


interface AddIntegrationDialogProps {
  open: boolean
  closeFunction: Dispatch<SetStateAction<boolean>>
}

function AddIntegrationDialog(props: AddIntegrationDialogProps) {
  const { state, dispatch } = useContext(OkrContext)
  const [integrationTitle, setIntegrationTitle] = useState('');
  const [integrationType, setIntegrationType] = useState('')
  const [integrationConfig, setIntegrationConfig] = useState({})
  const [authToken, setAuthToken] = useState('')
  // const integrationTypes = ['ClickApp','Jira','Monday','Salesforce','Zendesk']
  const integrationTypes = ['Jira','Salesforce']
  const { user } = useAuthenticator((context) => [context.user]);

  const handleClose = () => {
    props.closeFunction(false);
  };

  const handleSave = async () => {
    const integration = await API.graphql({
      query: createIntegration,
      variables: {
        input: {
          title: integrationTitle,
          type: integrationType,
          config: JSON.stringify(integrationConfig),
          accountId: state.account.id,
          groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
        }
      }
    })
    props.closeFunction(false);
  }

  return (
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="AddIntegrationDialogProps-title"
        aria-describedby="AddIntegrationDialogProps-description"
        style={{overflow: 'hidden'}}
      >
        <DialogTitle id="AddIntegrationDialogProps-title" style={{width: '400px'}}>
          {"Create New Integration"}
        </DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection:'column'}} style={{overflow: 'hidden'}}>
        <TextField fullWidth style={{margin: '8px 0'}} label="Title" value={integrationTitle} onChange={(event) => { setIntegrationTitle(event.target.value)}}></TextField>

        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={integrationType}
              label="Type"
              onChange={(event: SelectChangeEvent) => {
                setIntegrationType(event.target.value)
              }}
            >
              {integrationTypes.map(type => {
                return <MenuItem value={type} key={type}>{type}</MenuItem>
              })}
          </Select>

          </FormControl>
          <IntegrationConfig type={integrationType} setConfig={setIntegrationConfig} config={integrationConfig}></IntegrationConfig>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  );
}

interface IntegrationConfigProps {
  type: string;
  config: any;
  setConfig: Dispatch<SetStateAction<{}>>;
}

function IntegrationConfig(props: IntegrationConfigProps) {
  switch(props.type) {
    case 'Jira': {
      return <>
        <span> How to&nbsp;
          <a href="https://support.atlassian.com/atlassian-account/docs/manage-api-tokens-for-your-atlassian-account/" target="_blank">Create an API token</a>
        </span>
        <TextField label="Email"
          value={props.config.email || ''} onChange={(event) => { props.setConfig({
            ...props.config,
            email: event.target.value
          })}}>
        </TextField>
        <TextField label="Token" style={{margin: '8px 0'}}
          value={props.config.apiToken || ''} onChange={(event) => { props.setConfig({
            ...props.config,
            apiToken: event.target.value
          })}}
        ></TextField>
        <TextField label="Jira Domain" style={{margin: '8px 0'}}
          value={props.config.jiraDomain || ''} onChange={(event) => { props.setConfig({
            ...props.config,
            jiraDomain: event.target.value
          })}}
          helperText='If your Jira domain is "https://okrcalendar.atlassian.net/" enter "okrcalendar"'
        ></TextField>
      </>
    }
    case 'Monday':
      return <TextField sx={{margin: 4}} label="API Token" value={props.config.apiToken || ''} onChange={(event) => { props.setConfig({apiToken: event.target.value})}}></TextField>
  }
  return <div></div>
}
