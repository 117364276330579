import React, { memo, useState, useContext, useCallback } from 'react';
import _debounce from 'debounce';

import { NodeResizer } from '@reactflow/node-resizer';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import { OkrContext } from '../../../contexts/okr-context';

import '@reactflow/node-resizer/dist/style.css';

const ResizableNodeSelected = (props) => {
  const { state, dispatch } = useContext(OkrContext);
  const [note, setNote] = useState(props.data.label)
  const graphNode = state.flow.nodes.find(node => node.id === props.id)
  const updateNodes = useCallback(_debounce(nodes => {
    props.data.setNodes(nodes)
    dispatch({
      type: 'setNodes',
      payload: {
        nodes: nodes
      }
    })
  }), [props])
  return (
    <>
        <NodeResizer color="#ff0071" isVisible={props.selected} minWidth={100} minHeight={54} />
        <TextareaAutosize
        style={{height: '100%', width: '100%', background: '#ffffe3'}}
        value={note}
        onChange={(event) => {
          setNote(event.target.value)
          graphNode.data.label = event.target.value
          const newNodes = [...state.flow.nodes]
          newNodes[state.flow.nodes.indexOf(graphNode)] = graphNode
          updateNodes(state.flow.nodes)
        }}
      ></TextareaAutosize>
    </>
  );
};

export default memo(ResizableNodeSelected);