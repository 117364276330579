import { useContext, useEffect } from 'react'
import { styled } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { OkrContext } from '../../contexts/okr-context';
import { listGroups, listOkrTags } from '../../graphql/queries';
import { API } from 'aws-amplify';

const SideContainer = styled('div')({
    display: 'flex',
    height: '100%',
    width: '256px',
    flexDirection: 'column',
    padding: 8,
})

export default function CalendarSide() {
    const {state, dispatch} = useContext(OkrContext)



    useEffect(() => {
        if (!state.account) { return }

        const getOkrGroupList = async () => {
            let listGroupsResponse = await API.graphql({
                query: listGroups,
                variables: {
                filter: {
                    accountId: { eq: state.account.id }
                    },
                    limit: 10000
                }
            })
            if (listGroupsResponse.data.listGroups.items.length > 0) {
                dispatch({
                    type: 'setGroups',
                    payload: {
                        groupList: listGroupsResponse.data.listGroups.items
                    }
                })   
            }
        }

        
        const getTags = async () => {
            let listOkrTagsResponse = await API.graphql({
                query: listOkrTags,
                variables: {
                    filter: {
                        accountId: { eq: state.account.id }
                    },
                    limit: 10000
                }
            })
            dispatch({
                type: 'setTags',
                payload: {
                    tags: listOkrTagsResponse.data.listOkrTags.items || []
                }
            })   
        }

        getTags()
        getOkrGroupList()
      }, [state.account?.id])




    return  <SideContainer id="side-container">
                <Accordion disableGutters defaultExpanded square>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                    <Typography>Groups</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {state.groupList?.map(group => {
                    return <FormGroup>
                                <FormControlLabel
                                    key={`panel-label-${group.title}`}
                                    control={<Checkbox defaultChecked 
                                        sx={{
                                            color: group.color,
                                            '&.Mui-checked': {
                                                color: group.color,
                                                }
                                        }}/>}
                                    label={group.title}
                                />
                        </FormGroup>
                    })}       
                        
                    </AccordionDetails>
                </Accordion>
                
                <Accordion disableGutters defaultExpanded square>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                    <Typography>Tags</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    {state.tagList?.map(tag => {
                    return <FormGroup>
                                <FormControlLabel
                                    key={`panel-label-${tag.text}`}
                                    control={<Checkbox defaultChecked 
                                        sx={{
                                            color: tag.color,
                                            '&.Mui-checked': {
                                                color: tag.color,
                                                }
                                        }}/>}
                                    label={tag.text}
                                />
                        </FormGroup>
                    })}       
                        
                    </AccordionDetails>
                </Accordion>
                        
            </SideContainer>            
}