import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { API  } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api'
import { createCandidate } from '../../graphql/mutations';
import './contact.css'
import Footer from './footer';


export default function ContactPage() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const submit = async () => {
        await API.graphql({
            query: createCandidate,
            variables: {
              input: {
                name,
                email,
              },
            },
            authMode: GRAPHQL_AUTH_MODE.API_KEY
        })
    }
    return (
        <><div className='contact-container'>
            <div id="decoration"></div>
            <div className='contact-left'>
                <div className='contact-title'>Contact our sales team</div>
                <div className='contact-list-head'>With OKR Calendar you can:</div>
                <ul>
                    <li>Build and manage goals for your whole company</li>
                    <li>Integrate with your CRM and Project managent tools</li>
                    <li>Track conversations and manage feedback for your goals</li>
                </ul>
            </div>
            <Paper className='contact-right'>
                <TextField
                    required
                    id="name"
                    label="Name"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
                <TextField
                    required
                    id="email"
                    label="Email"
                    value={email}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(event.target.value);
                    }}
                />
                
                <TextField
                    id="company"
                    label="Company Name"
                />
                
                <TextField
                    id="job"
                    label="Job Title"
                />

                <Button 
                    variant='contained'
                    sx={{width: '80px'}} 
                    style={{float: 'right', position: 'relative'}}
                    onClick={submit}
                >Submit</Button>
            </Paper>
        </div>
        <Footer></Footer>
        </>
    );
}