import React, { useEffect, useContext, useState, useCallback} from 'react'
import Paper from '@mui/material/Paper';
import { API } from 'aws-amplify';
import _debounce from 'debounce';
import { saveToken } from '../../graphql/mutations';
import OutlinedInput from '@mui/material/OutlinedInput';
import Slider from '@mui/material/Slider';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { OkrContext } from '../../contexts/okr-context';
import { getJiraStatuses, getJiraProjects, updateIntegration } from '../../graphql/mutations';
import './jira-integration.css'
import { listIntegrations } from '../../graphql/queries';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function JiraIntegration() {
    const {state, dispatch} = useContext(OkrContext)

    useEffect(() => {
        const checkAccessStatus = async () => {
            // const token = await getJiraAccessToken()
            // const status = await checkAccess('eyJraWQiOiJmZTM2ZThkMzZjMTA2N2RjYTgyNTg5MmEiLCJhbGciOiJSUzI1NiJ9.eyJqdGkiOiJlYzAxOTQ5Ny1jODI0LTQxYTAtYWQ5Yy01M2QxNDljMjQyZDAiLCJzdWIiOiI2MzA2OGYwNjExMmUyN2RmMWUwYWNlNTgiLCJuYmYiOjE2ODQxNjQxMTcsImlzcyI6Imh0dHBzOi8vYXV0aC5hdGxhc3NpYW4uY29tIiwiaWF0IjoxNjg0MTY0MTE3LCJleHAiOjE2ODQxNjc3MTcsImF1ZCI6IlVOYmJMcnJZNFhneEJjTFlqclUzRklaS1RxTzI3MzEwIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL2VtYWlsRG9tYWluIjoib2tyY2FsZW5kYXIuY29tIiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL29hdXRoQ2xpZW50SWQiOiJVTmJiTHJyWTRYZ3hCY0xZanJVM0ZJWktUcU8yNzMxMCIsImh0dHBzOi8vaWQuYXRsYXNzaWFuLmNvbS9hdGxfdG9rZW5fdHlwZSI6IkFDQ0VTUyIsImh0dHBzOi8vaWQuYXRsYXNzaWFuLmNvbS9zZXNzaW9uX2lkIjoiZTdiOTY1NTUtODAwMi00NmIzLWE2ZjctMDgzOWZjMzRlOTM4IiwiaHR0cHM6Ly9hdGxhc3NpYW4uY29tL2ZpcnN0UGFydHkiOmZhbHNlLCJodHRwczovL2F0bGFzc2lhbi5jb20vdmVyaWZpZWQiOnRydWUsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9zeXN0ZW1BY2NvdW50SWQiOiI3MTIwMjA6MzM3MTRjODYtNzYxNS00NzBhLWE4M2YtNWRhMmEzYTQ2OGJhIiwiaHR0cHM6Ly9pZC5hdGxhc3NpYW4uY29tL3Byb2Nlc3NSZWdpb24iOiJ1cy1lYXN0LTEiLCJjbGllbnRfaWQiOiJVTmJiTHJyWTRYZ3hCY0xZanJVM0ZJWktUcU8yNzMxMCIsImh0dHBzOi8vaWQuYXRsYXNzaWFuLmNvbS91anQiOiI3YjIxNTljMC01YzkxLTQxYzctOTYzNS00YzAzOGEyODRjMmQiLCJjbGllbnRfYXV0aF90eXBlIjoiUE9TVCIsInNjb3BlIjoicmVhZDpqaXJhLXdvcmsiLCJodHRwczovL2F0bGFzc2lhbi5jb20vM2xvIjp0cnVlLCJodHRwczovL2lkLmF0bGFzc2lhbi5jb20vdmVyaWZpZWQiOnRydWUsImh0dHBzOi8vYXRsYXNzaWFuLmNvbS9zeXN0ZW1BY2NvdW50RW1haWxEb21haW4iOiJjb25uZWN0LmF0bGFzc2lhbi5jb20iLCJodHRwczovL2F0bGFzc2lhbi5jb20vc3lzdGVtQWNjb3VudEVtYWlsIjoiZjUzOTExOWUtMmY3Mi00YmYyLTg1NDQtZWM2YmM1OGExM2FiQGNvbm5lY3QuYXRsYXNzaWFuLmNvbSJ9.B47E0ZNuJGCxSDhMeXqO1lY84QIxzwcT-noF1pGlcDiiS6vWIau6ZJTEeL_0g8IQVhDnexi0GQm02yJZU4r2JFm4-x5AtNUTjR9_7tvNyqHTbyqMEdjdkIuyalbJNIt44ZMLUQQ2K5bkRs5kuPZnt4bMz65wk3s7wM9F08Jyup4klmiUoZdWwfb49TR6vHdUviy3SnfBvYXkIxhLLzhl-jSy28L_GM3HdoVC3gZeb6JN6uULyfnwJWyd264aVCPVwz1KorhFJaZUKtj74q09Zgm96XitcDCgv_MpbdAHmfrf9MFeiCZD6gbuSggOM_fZwYfPRCHRyykw52fGkfuNGg')
        }
        checkAccessStatus()
    }, [])

    const [allJiraProjects, setAllJiraProjects] = useState<any[]>([])

    const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
    const [selectedStatuses, setSelectedStatuses] = useState<string[]>([]);
    const [allJiraStatuses, setAllJiraStatuses] = useState<any[]>([])
    const [mappedStatuses, setMappedStatuses] = useState({} as any)
    const [mappedCompletion, setMappedCompletion] = useState([] as any)


    const handleProjectsChange = (event: SelectChangeEvent<typeof selectedProjects>) => {
        let { target: { value }, } = event;
        value = typeof value === 'string' ? value.split(',') : value
        saveJiraIntegration('selectedProjects', value)
        setSelectedProjects(value);
    };

    const handleStatusesChange = (event: SelectChangeEvent<typeof selectedStatuses>) => {
        let { target: { value }, } = event;
        value = typeof value === 'string' ? value.split(',') : value
        saveJiraIntegration('selectedStatuses', value)
        setSelectedStatuses(value);
    };

    useEffect(() => {
        if (!state.okrStatusOptions) { return }
        const mapStatuses = {} as any
        state.okrStatusOptions.forEach((option) => {
            mapStatuses[option.id] = [...allJiraStatuses].map(s => {
                let selected = false
                if (state.editingIntegration?.config?.mappedStatuses) {
                    const value = state.editingIntegration.config.mappedStatuses[option.id]?.find((option: any) => option.id === s.id)
                    selected = value.selected
                }
                return {
                    id: s.id,
                    name: s.name,
                    selected: selected
                }
            })
        })
        setMappedStatuses(mapStatuses)

    }, [state.editingIntegration, state.okrStatusOptions])

    useEffect(() => {
        setMappedCompletion(allJiraStatuses.map(s => {
            let completion = 0
            if (state.editingIntegration?.config?.mappedCompletion) {
                const value = state.editingIntegration.config.mappedCompletion.find((option: any) => option.id === s.id)
                completion = value.completion
            }
            return {
                id: s.id,
                name: s.name,
                completion
            }
        }))
    }, [allJiraStatuses])

    useEffect(() => {
        const integrationId = window.location.pathname.split('/')[4]
        const init = async () => {
            const integrationData = await API.graphql({
                query: listIntegrations,
                variables: {
                    filter: {
                      id: { eq: integrationId }
                    },
                    limit: 10000
                }
            }) as any
            const integration = integrationData.data.listIntegrations.items[0]
            integration.config = JSON.parse(integration.config)
            dispatch({
                type: 'setEditingIntegration',
                payload: {
                    integration
                }
            })


            const statusesResponse = await API.graphql({
                query: getJiraStatuses,
                variables: {
                    integrationId
                }
            }) as any
            const jiraStatuses = JSON.parse(statusesResponse.data.getJiraStatuses).body
            setAllJiraStatuses(jiraStatuses)



            const projectsResponse = await API.graphql({
                query: getJiraProjects,
                variables: {
                    integrationId
                }
            }) as any
            const jiraProjects = JSON.parse(projectsResponse.data.getJiraProjects).body
            setAllJiraProjects(jiraProjects)



        }
        init()
    }, [])


    const saveJiraIntegration =  useCallback(_debounce(async (field: string, value: any) => {
        if (!state.editingIntegration) { return }
        const config = {...state.editingIntegration.config}
        config[field] = value
        dispatch({
            type: 'setEditingIntegrationConfig',
            payload: { config }
        })
        const updateResult = await API.graphql({
            query: updateIntegration,
            variables: {
                input: {
                    id: state.editingIntegration.id,
                    config: JSON.stringify(config)
                }
            }
        })

    }, 500), [state.editingIntegration])

    if (!state.editingIntegration) { return <></> }

    return (
        <Paper style={{height: '90%'}} className='integration-container'>
            <div style={{display: 'flex'}}>
                <TextField
                    style={{margin: '8px'}}
                    id="jira-title-edit"
                    label="Title"
                    value={state.editingIntegration.title}
                    onChange={(event) => {
                        dispatch({
                            type: 'setEditingIntegrationTitle',
                            payload: {
                                title: event.target.value
                            }
                        })
                    }}
                />
                <TextField
                    style={{margin: '8px'}}
                    id="jira-token-edit"
                    label="Token"
                    type="password"
                    value={state.editingIntegration.config.token || 'passwordpassword'}
                    onChange={(event) => {
                        dispatch({
                            type: 'setEditingIntegration',
                            payload: {
                                title: event.target.value
                            }
                        })
                    }}
                />
            </div>
            <div className='jira-config-section'>
                <div className='jira-section-label'>Filter Jira projects and statuses</div>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-checkbox-label">Selected Projects</InputLabel>
                    <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    value={selectedProjects}
                    onChange={handleProjectsChange}
                    input={<OutlinedInput label="Selected Projects" />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    >
                    {allJiraProjects.map((project) => (
                        <MenuItem key={project.id} value={project.name}>
                            <Checkbox checked={selectedProjects.indexOf(project) > -1} />
                            <ListItemText primary={project.name} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>


                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="jira-status">Jira Statuses</InputLabel>
                    <Select
                    labelId="jira-status"
                    id="jira-status-checkbox"
                    multiple
                    value={selectedStatuses}
                    onChange={handleStatusesChange}
                    input={<OutlinedInput label="Jira Statuses" />}
                    renderValue={(selected) => allJiraStatuses.filter(s => selected.includes(s.id)).map(s => s.name).join(', ')}
                    MenuProps={MenuProps}
                    >
                    {allJiraStatuses?.map((status) => (
                        <MenuItem key={`j-${status.id}`} value={status.id}>
                            <Checkbox checked={selectedStatuses.indexOf(status.id) > -1} />
                            <ListItemText primary={status.name} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
            </div>
            <div className='jira-config-section'>
                <div className='jira-section-label'>Select which status options in Jira should map to options in OKR Calendar</div>
                {state.okrStatusOptions.map((status) => {
                    return <>
                        <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id={`${status.id}-status-multi`}>{status.label}</InputLabel>
                    <Select
                    labelId={`${status.id}-status-multi`}
                    id={`${status.id}-status-multi-select`}
                    multiple
                    value={mappedStatuses[status.id]?.filter((s: any) => s.selected).map((s: any) => s.id) || []}
                    onChange={(event: SelectChangeEvent<typeof selectedProjects>) => {
                            let { target: { value }, } = event;
                            value = typeof value === 'string' ? value.split(',') : value
                            const mapStatuses = {...mappedStatuses} as any
                            mappedStatuses[status.id].forEach((mvalue: any) => {
                                if (value.includes(mvalue.id)) {
                                    mvalue.selected = true
                                } else {
                                    mvalue.selected = false
                                }
                            })
                            setMappedStatuses(mapStatuses);
                            saveJiraIntegration('mappedStatuses', mapStatuses)
                        }
                    }
                    input={<OutlinedInput label="Selected Projects" />}
                    renderValue={(selected) => allJiraStatuses.filter(s => selected.includes(s.id)).map(s => s.name).join(', ')}
                    MenuProps={MenuProps}
                    >
                    {mappedStatuses[status.id]?.map((status: any) => (
                        <MenuItem key={`m-${status.id}`} value={status.id}>
                            <Checkbox checked={status.selected || false} />
                            <ListItemText primary={status.name} />
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                    </>
                })}
            </div>
            <div className='completion-container jira-config-section'>
                <div className='jira-section-label'>Configure automatic completion percent updates based on Jira statuses</div>

                <div className='completion-row'>
                    <span style={{width: '200px'}}>Jira Status</span>
                    <span>Completion Percent</span>
                </div>
                {mappedCompletion.map((completion: any) => {
                    return <div key={`c-${completion.id}`} className='completion-row'>
                        <span className='completion-label' style={{width: '200px'}}>{completion.name}</span>
                        <span>

                        <FormControl sx={{ m: 1, minWidth: 220, padding: '0 8px', margin: '36px 8px 8px 8px' }}>
                            <Slider
                                aria-label="Completion Percent"
                                value={completion.completion}
                                onChange={(event, value) => {
                                    if (typeof value === 'number') {
                                        const newCompletion = [...mappedCompletion]
                                        const toUpdate = newCompletion.findIndex(c => c.id === completion.id)
                                        newCompletion[toUpdate].completion = value
                                        setMappedCompletion(newCompletion)
                                        saveJiraIntegration('mappedCompletion', newCompletion)
                                    }
                                }}
                                getAriaValueText={() => `${completion.completion}`}
                                valueLabelDisplay="on"
                            />
                        </FormControl>
                        </span>
                    </div>
                })}
            </div>
        </Paper>
    );
}
