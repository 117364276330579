import { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { OkrContext, Integration } from '../../contexts/okr-context';
import React from 'react';
import { getIntegration } from '../../graphql/queries';
import OutlinedInput from '@mui/material/OutlinedInput';
import { getJiraIssues } from '../../graphql/mutations';
import { API } from 'aws-amplify';

const jiraOptions = [
  'IT-74 UI v2',
  'IT-103 slack outbound hook',
  'IT-139 user groups in graph',
  'IT-140 fix date bug',
  'IT-141 fix edge node bug',
  'IT-143 group auto placement',
  'IT-144 add user images',
  'IT-145 Stripe v2',
  'IT-146 fix status drag and drop',
  'IT-147 first time user experience',
]

const jiraStatuses = [
  'backlog',
  'in dev',
  'in progress',
  'done'
]

const jiraBoards = [
  'IT',
  'PROD'
]

export default function IntegrationPicker() {
    const { state, dispatch } = useContext(OkrContext)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedIndex, setSelectedIndex] = useState('');
    const [okrIntegrations, setOkrIntegrations] = useState<Array<Integration>>([])
    const open = Boolean(anchorEl);
    const [selectedIntegrations, setSelectedIntegrations] = useState<any[]>([])
    const [jiraIssues, setJiraIssues] = useState<any[]>([])
    const [selectedIssues, setSelectedIssues] = useState<any[]>([])
    const [todo, setTodo] = useState(false)
    const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = async (integration: Integration) => {
        setSelectedIndex(integration.id);
        const issuesResponse = await API.graphql({
            query: getJiraIssues,
            variables: {
              integrationId: integration.id
            }
        }) as any
        const issues = JSON.parse(issuesResponse.data.getJiraIssues).body
        setJiraIssues(issues)
        setAnchorEl(null);
        setTodo(true)
        setOkrIntegrations([...okrIntegrations].concat(integration))
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
    <div style={{margin: '8px'}}>
      <Button variant="outlined" startIcon={<AddCircleIcon />}
            onClick={(event) => {
                handleClickListItem(event)
            }}>
                Add Integration
      </Button>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {state.integrationList.map((integration) => (
          <MenuItem
            key={integration.id}
            // selected={index === selectedIndex}
            onClick={(event) => {
              handleMenuItemClick(integration)
            }}
          >
            {integration.title}
          </MenuItem>
        ))}
      </Menu>
      <div style={{ height: '16px'}}></div>
      {okrIntegrations.map(okrIntegration => {
        switch(okrIntegration.type) {
          case 'Jira': {
            return <IntegrationPanels></IntegrationPanels>
          }
          default: {
            return <></>
          }
        }
      })}
      {/* {selectedIntegrations.map(selectedIntegration => {
        return <> */}
        {todo &&
          <FormControl sx={{ m: 1, width: 300 }}>
              <InputLabel id="jira-issues">Jira Issues</InputLabel>
              <Select
              labelId="jira-issues"
              id="jira-issues-checkbox"
              multiple
              value={selectedIssues}
              onChange={(event) => {
                let { target: { value }, } = event;
                value = typeof value === 'string' ? value.split(',') : value
                const values = selectedIssues.concat(value)
                setSelectedIssues(values)

              }}
              input={<OutlinedInput label="Jira Statuses" />}
              renderValue={(selected) => jiraIssues.filter(s => selected.includes(s.id)).map(s => `${s.key} ${s.summary}`).join(', ')}
              MenuProps={MenuProps}
              >
              {jiraIssues?.map((issue) => (
                  <MenuItem key={`j-${issue.id}`} value={issue.id}>
                      <Checkbox checked={selectedIssues.indexOf(issue.id) > -1} />
                      <ListItemText primary={`${issue.key} ${issue.summary}`} />
                  </MenuItem>
              ))}
              </Select>
          </FormControl>
}
        {/* </>
      })} */}
    </div>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


const IntegrationPanels = () => {
  return <>
  </>
}


const JiraIntegration = () => {
  const [board, setBoard] = useState('')
  const [stories, setStories] = useState<string[]>([])
  const [statuses, setStatuses] = useState<string[]>([])

  const onStatusChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setStatuses(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const onStoriesChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setStories(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return <>
    <FormControl fullWidth style={{margin: '8px 0px'}}>
      <InputLabel id="jira-board">Board</InputLabel>
      <Select
        labelId="jira-board"
        id="jira-board-select"
        value={board}
        label="Board"
        onChange={(event) => setBoard(event.target.value)}
      >
        {jiraBoards.map(jiraBoard => <MenuItem value={jiraBoard} key={jiraBoard}>{jiraBoard}</MenuItem>)}
      </Select>
    </FormControl>

    <FormControl fullWidth style={{margin: '8px 0px'}}>
      <InputLabel id="jira-story">Stories</InputLabel>
      <Select
        multiple
        labelId="jira-story"
        id="jira-story-select"
        value={stories}
        input={<OutlinedInput label="Name" />}
        label="Stories"
        onChange={onStoriesChange}
      >
        {jiraOptions.map(jiraOption => <MenuItem value={jiraOption} key={jiraOption}>{jiraOption}</MenuItem>)}
      </Select>
    </FormControl>

    <div>Has Any:</div>
    <FormControl fullWidth style={{margin: '8px 0px'}}>
      <InputLabel id="jira-status">Status</InputLabel>
      <Select
        multiple
        labelId="jira-status"
        id="jira-status-select"
        value={statuses}
        input={<OutlinedInput label="Name" />}
        label="Stories"
        onChange={onStatusChange}
      >
        {jiraStatuses.map(jiraStatus => <MenuItem value={jiraStatus} key={jiraStatus}>{jiraStatus}</MenuItem>)}
      </Select>
    </FormControl>
  </>
}
