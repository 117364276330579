/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://gww5oxbvovfhblfr6hmzydomwy.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-2l537ibogjhrtnuwgd3flnl6ey",
    "aws_cloud_logic_custom": [
        {
            "name": "apiv1",
            "endpoint": "https://0vcsza18w6.execute-api.us-west-2.amazonaws.com/staging",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:c34d5af8-601e-4552-bb47-be20d6544136",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_RKMh8z3zI",
    "aws_user_pools_web_client_id": "7g01lkp8v5rk4khjuj598lbmdj",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "okra-prod-staging",
    "aws_content_delivery_bucket_region": "us-west-2",
    "aws_content_delivery_url": "https://d2s0moeq1drtnk.cloudfront.net"
};


export default awsmobile;
