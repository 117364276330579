import { useContext, useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {  useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import GroupIcon from '@mui/icons-material/Group';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ApiIcon from '@mui/icons-material/Api';
import { Outlet, Link } from 'react-router-dom';
import { API } from 'aws-amplify';
import { createAccount, createUser, createOkrStatus } from '../../graphql/mutations';
import { listAccounts, listBoards, listOkrStatuses, listUsers } from '../../graphql/queries'
import { OkrContext } from '../../contexts/okr-context';
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const { state, dispatch } = useContext(OkrContext)
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const { user } = useAuthenticator((context) => [context.user]);
  const location = useLocation();

  useEffect(() => { //TODO... move this somewhere else
    // execute on location change
    let split = location.pathname.split('/')

    if (split.length === 4) {
      if (!state.currentBoard || split[3] !== state.currentBoard?.friendlyId) {
        dispatch({
          type: 'setCurrentBoardByFriendlyId',
          payload: {
            boardFriendlyId: split[3]
          }
        })
      }
    }
  }, [location.pathname]);

  useEffect(() => {
    let account = {}
    const getAccount = async () => {
      let accountResponse = await API.graphql({
        query: listAccounts
      })
      if (accountResponse.data.listAccounts.items.length === 0) {

        const createdAccount = await API.graphql({
          query: createAccount,
          variables: {
            input: {
              name: user.attributes.email.split('@')[1],
              groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
            }
          }
        })

        accountResponse = await API.graphql({
          query: listAccounts
        })

        const account = accountResponse.data.listAccounts.items[0]
        const okrStatusOptions = [
          { label: 'Green', color: '{"r":0,"g":121,"b":19,"a":1}', lolightColor:'{"r":200,"g":255,"b":200,"a":1}', accountId: account.id, order: 1},
          { label: 'Yellow', color: '{"r":255,"g":248,"b":22,"a":1}', lolightColor: '{"r":255,"g":255,"b":200,"a":0.4}', accountId: account.id, order: 2 },
          { label: 'Red', color: '{"r":207,"g":0,"b":0,"a":1}', lolightColor: '{"r":255,"g":200,"b":200,"a":1}', accountId: account.id, order: 3 }
        ]
        const options = []
        for (const option of okrStatusOptions) {
          const statusRequest = await API.graphql({
            query: createOkrStatus,
            variables: {
              input: {
                ...option,
                groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
              }
            }
          })
          options.push(statusRequest.data.createOkrStatus)
        }
      }
      dispatch({
        type: 'setAccount',
        payload: {
          account: accountResponse.data.listAccounts.items[0]
        }
      })
      account = accountResponse.data.listAccounts.items[0]

    } //TODO limit by account
    const getUsers = async () => {
      let usersResponse = await API.graphql({
        query: listUsers,
        variables: { limit: 10000 }
      })
      let currentUser = usersResponse.data.listUsers.items.find(u => u.email === user.attributes.email)

      if (!currentUser) {
        await API.graphql({
          query: createUser,
          variables: {
            input: {
              accountId: account.id,
              name: user.attributes.name,
              email: user.attributes.email,
              cognitoId: user.username,
              groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
            }
          }
        })

        usersResponse = await API.graphql({
          query: listUsers,
          variables: { limit: 10000 }
        })
      }
      dispatch({
        type: 'setUsers',
        payload: {
          users: usersResponse.data.listUsers.items
        }
      })
      currentUser = usersResponse.data.listUsers.items.find(u => u.email === user.attributes.email)
      dispatch({
        type: 'setCurrentUser',
        payload: {
          user: currentUser
        }
      })
    }
    const fetchBoards = async () => { //TODO this is here since it helps other pages, but could be in each page instead
      const boards = await API.graphql({
        query: listBoards,
        variables: { limit: 10000 }
      })

      dispatch({
        type: 'setBoards',
        payload: {
          boardList: boards.data.listBoards.items
        }
      })
      let split = location.pathname.split('/')

      if (split.length === 4) {
        if (!state.currentBoard || split[3] !== state.currentBoard?.friendlyId) {
          const board = boards.data.listBoards.items.find(item => item.friendlyId === split[3])
          dispatch({
            type: 'setCurrentBoard',
            payload: {
              board
            }
          })
        }
      }
    }
    const getOkrStatuses = async () => {
      const statuses = await API.graphql({
        query: listOkrStatuses,
        variables: { limit: 10000 }
      })
      const options = statuses.data.listOkrStatuses.items
      dispatch({
        type: 'setOkrStatusOptions',
        payload: {
          okrStatusOptions: options
        }
      })
    }
    const init = async() => {
      await getAccount()
      fetchBoards()
      getUsers()
      getOkrStatuses()

    }
    init()
  }, [])


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const route = useAuthenticator((context) => [context.route]);
  let path = window.location.href.split('/')
  path = path[path.length - 1]
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{background: 'white', color: 'rgb(3,45,96)'}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {decodeURI(path)}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer className='left-drawer' variant="permanent" open={open}
        PaperProps={{
          sx: {backgroundColor: 'rgba(190, 220, 210, .4)'}
        }}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        <ListItem key={'Dashboard'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              > <Link to="/app/dashboard">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <HomeIcon />
                </ListItemIcon>
                </Link>
                <ListItemText primary={<Link to="/app/Dashboard">Dashboard</Link>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Goals'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              > <Link to="/app/Goals">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <TrackChangesIcon />
                </ListItemIcon>
                </Link>
                <ListItemText primary={<Link to="/app/Goals">Goals</Link>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Integrations'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <ApiIcon />
                </ListItemIcon>
                <ListItemText primary={<Link to="/app/Integrations">Integrations</Link>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Groups'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={<Link to="/app/Groups">Groups</Link>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Account'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={<Link to="/app/Account">Account</Link>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
            <ListItem key={'Notifications'} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <NotificationsNoneIcon />
                </ListItemIcon>
                <ListItemText primary={<Link to="/app/Notifications">Notifications</Link>} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
        </List>
        <Divider />
      </Drawer>
      <Box className='container-container' component="main" sx={{ flexGrow: 1, p: 3 }} style={{backgroundColor: 'rgba(240, 250, 250, .3)', height: '100vh', overflow: 'hidden'}}>
        <DrawerHeader />
        <Outlet className='why'></Outlet>
      </Box>
    </Box>
  );
}