import React, { useCallback, useRef, useState, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import _debounce from 'debounce';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Background,
  Controls,
  ControlButton
} from 'reactflow';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useLocation } from 'react-router-dom';
import 'reactflow/dist/style.css';

import './flow-style.css';
import OKRNode from './okr-node'
import GroupNode from './group-node';
import NoteNode from './note-node';
import SideBar from './side-bar';
import EditOkrDialog from '../../edit-okr-dialog';
import { OkrContext } from '../../../contexts/okr-context';
import { updateOkrGraph, createOkrGraph } from '../../../graphql/mutations';
import { listOkrItems, listOkrStatuses, listBoards, listOkrGraphs, listGroups, listOkrTags } from '../../../graphql/queries';
import { API } from 'aws-amplify';

const nodeTypes = {
  okr: OKRNode,
  group: GroupNode,
  note: NoteNode
};

const getId = () => uuid();

const fitViewOptions = {
  padding: 3,
};


const AddNodeOnEdgeDrop = () => {

  const { state, dispatch } = useContext(OkrContext);

  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const connectingNode = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(state.flow.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(state.flow.edges);
  const [target, setTarget] = useState(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const { project } = useReactFlow();
  const { user } = useAuthenticator((context) => [context.user]);
  const location = useLocation();
  const nodesRef = useRef()
  nodesRef.current = nodes
  useEffect(() => {
    setEdges(state.flow.edges)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.flow.edges.length])

  useEffect(() => {
    if (!state.currentBoard?.id) { return }
    const getOkrItemList = async () => {
      let okrItemResponse = await API.graphql({
        query: listOkrItems,
        variables: {
          filter: {
            boardId: { eq: state.currentBoard.id }
          },
          limit: 10000
        }
      })
      dispatch({
        type: 'setOkrItemList',
        payload: {
          okrList: okrItemResponse.data.listOkrItems.items
        }
      })

    }
    const getOkrItemStatus = async () => {
      let okrItemResponse = await API.graphql({
        query: listOkrStatuses,
        variables: {
          limit: 10000
        }
      })
      dispatch({
        type: 'setOkrStatusOptions',
        payload: {
          okrStatusOptions: okrItemResponse.data.listOkrStatuses.items
        }
      })

    }

    const getGraph = async () => {
      if (!state.currentBoard) { return }
      let listOkrGraphsResponse = await API.graphql({
        query: listOkrGraphs,
        variables: {
          filter: {
            boardId: { eq: state.currentBoard.id }
          },
          limit: 10000
        }
      })
      let graph = listOkrGraphsResponse.data.listOkrGraphs.items[0]
      const graphNodes = graph.nodes ? JSON.parse(graph.nodes) : []
      graphNodes.forEach(graphNode => {
        graphNode.data.setNodes = setNodes
      })

      dispatch({
        type: 'setFlow',
        payload: {
          graph: {
            id: graph.id,
            nodes: graphNodes,
            edges: graph.edges ? JSON.parse(graph.edges) : []
          }
        }
      })
      setNodes(graphNodes)
      setEdges(() => graph.edges ? JSON.parse(graph.edges) : [])
    }

    getGraph()
    getOkrItemList()
    getOkrItemStatus()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentBoard?.id])

  useEffect(() => {
    if (!state.account) { return }

    const getOkrGroupList = async () => {
        let listGroupsResponse = await API.graphql({
            query: listGroups,
            variables: {
            filter: {
                accountId: { eq: state.account.id }
                }
            }
        })
        if (listGroupsResponse.data.listGroups.items.length > 0) {
            dispatch({
                type: 'setGroups',
                payload: {
                    groupList: listGroupsResponse.data.listGroups.items
                }
            })
        }
    }


    const getTags = async () => {
        let listOkrTagsResponse = await API.graphql({
            query: listOkrTags,
            variables: {
            filter: {
                accountId: { eq: state.account.id }
                }
            }
        })
        dispatch({
            type: 'setTags',
            payload: {
                tags: listOkrTagsResponse.data.listOkrTags.items || []
            }
        })
    }

    getTags()
    getOkrGroupList()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.account?.id])


  useEffect(() => {
    const fetchBoards = async () => {
      const boards = await API.graphql({
        query: listBoards,
        variables: { limit: 10000 }
      })

      dispatch({
        type: 'setBoards',
        payload: {
          boardList: boards.data.listBoards.items
        }
      })
      let split = location.pathname.split('/')

      if (split.length === 4) {
        if (!state.currentBoard || split[3] !== state.currentBoard?.friendlyId) {
          const board = boards.data.listBoards.items.find(item => item.friendlyId === split[3])
          dispatch({
            type: 'setCurrentBoard',
            payload: {
              board
            }
          })
        }
      }
    }
    fetchBoards()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const onConnect = useCallback((params) => setEdges((eds) => {
    const edge = addEdge(params, eds)
    dispatch({
      type: 'addEdge',
      payload: { edge: edge[0] }
    })
    return edge
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  const onConnectStart = useCallback((sevent, node) => {
    connectingNodeId.current = node.nodeId;
    connectingNode.node = { ...node }
    const connectingFlowNode = nodes.find(n => n.id === node.nodeId)
    if (connectingFlowNode) {
      const connectingOkr = state.okrList.find(okr => okr.id === connectingFlowNode.data.okrId)
      dispatch({
        type: 'setConnectingOkr',
        payload: {
          connectingOkr
        }
      })
    }
    sevent.stopPropagation()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addToGroup = (node, group) => {
    const newNodes = [...nodes]
    const toUpdate = newNodes.find((n) => n.id === node.id)
    toUpdate.parentNode = group
    toUpdate.position = {x: 40, y: 136 }
    toUpdate.extent = 'parent'
    setNodes(newNodes)
    dispatch({
      type: 'setNodes',
      payload: {
        nodes: newNodes
      }
    })
  }

  const onConnectEnd = useCallback(
    (event) => {
      const targetIsPane = event.target.classList.contains('react-flow__pane');
      const targetisGroup = event.target.classList.contains('group-node')
      if (targetIsPane || targetisGroup) {
        // we need to remove the wrapper bounds, in order to get the correct position
        dispatch({
          type: 'setOkrEditIsOpen',
          payload: {
            isEditOkrOpen: true,
            editOkrId: false
          }
        })
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = getId();
        const newNode = {
          id,
          type: 'okr',
          // we are removing the half of the node width (75) to center the new node
          position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
          style: {height: 200},
          data: { label: `Node ${id}`, setNodes },
        };
        if (targetisGroup) {
          newNode.parent = event.target["data-id"]
        }
        if (connectingNode) {
          const params = {
            source: connectingNode.node.nodeId,
            sourceHandle: connectingNode.node.handleId,
            target: newNode.id
          }
          setEdges((eds) => {
            const newEdge = addEdge(params, eds)
            dispatch({
              type: 'addEdge',
              payload: { edge: newEdge[0] }
            })
            return newEdge
          })

        }
        dispatch({
          type: 'setEditNode',
          payload: {
            editNode: newNode
          }
        })
        dispatch({
          type: 'addNode',
          payload: {
            node: newNode
          }
        })
      } else {
        // const type = event.dataTransfer.getData('application/reactflow');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData('application/reactflow');
      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const id = getId()
      const newNode = {
        id,
        type,
        position,
        style: {height: 300, width: 300},
        data: { label: `${type} node`, setNodes },
      };
      const newNodes = [...nodesRef.current].concat(newNode)
      setNodes(newNodes)
      dispatch({
        type: 'setNodes',
        payload: {
          nodes: newNodes
        }
      })
      if (type === 'okr') {
        dispatch({
          type: 'setEditNode',
          payload: {
            editNode: newNode
          }
        })
        dispatch({
          type: 'setOkrEditIsOpen',
          payload: {
            isEditOkrOpen: true,
            editOkrId: false
          }
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reactFlowInstance]
  );

  const onNodeDragStart = (event, node) => {
    dragRef.current = node;
  };

  const onNodeDrag = (evt, node) => { //This is to add nodes to groups
    // calculate the center point of the node from position and dimensions
    const centerX = node.position.x + node.width / 2;
    const centerY = node.position.y + node.height / 2;

    // find a node where the center point is inside
    const targetNode = nodes.find(
      (n) =>
        centerX > n.position.x &&
        centerX < n.position.x + n.width &&
        centerY > n.position.y &&
        centerY < n.position.y + n.height &&
        n.id !== node.id // this is needed, otherwise we would always find the dragged node
    );
    setTarget(targetNode);
  };

  const onNodeDragStop = (evt, node) => {
    if (target?.type === 'group' && !node.parentNode && node.type !== 'group') {
      addToGroup(node, target.id)
    }
    setTarget(null);
    dragRef.current = null;
  };

  const saveGraph = useCallback(_debounce(async ( input) => {
    const graphNodes = JSON.parse(input.nodes)
    graphNodes.forEach(graphNode => {
      graphNode.data = JSON.parse(JSON.stringify(graphNode.data)) //so we're only working on a deep copy
      delete graphNodes.setNodes //so the function isn't saved to the db
    })
    input.nodes = JSON.stringify(graphNodes)
    const graph = await API.graphql({
      query: updateOkrGraph,
      variables: {
          input
      }
    })
    // dispatch({
    //   type: 'setFlow',
    //   payload: {
    //     graph: {
    //       id: input.id,
    //       nodes: graphNodes,
    //       edges: input.edges ? JSON.parse(input.edges) : []
    //     }
    //   }
    // })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [])

  const isInitialRender = useRef(true);
  useEffect(() => {
    if (!state.flow.id) {
      return
    }
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    if (nodes.length === 0) { return }
    const input = {
      boardId: state.currentBoard.id,
      edges: JSON.stringify(edges),
      nodes: JSON.stringify(nodes)
    }
    input.id = state.flow.id
    saveGraph(input)
  }, [nodes, edges])
  // this ref stores the current dragged node
  const dragRef = useRef(null);
  return (
    <div className="wrapper dndflow" ref={reactFlowWrapper} style={{height: '100vh', width: '100vw'}}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        snapToGrid={true}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={setReactFlowInstance}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onNodeDragStart={onNodeDragStart}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        fitView
        fitViewOptions={fitViewOptions}
      >
        <Background></Background>
        <Controls position='bottom-right'>
            <ControlButton onClick={() => {

            }}></ControlButton>
        </Controls>
        <SideBar></SideBar>
      </ReactFlow>
      {state.isEditOkrOpen && <EditOkrDialog nodes={nodes} setNodes={setNodes}></EditOkrDialog>}
    </div>
  );
};

export default () => (
  <ReactFlowProvider>
    <AddNodeOnEdgeDrop />
  </ReactFlowProvider>
);
