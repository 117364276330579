import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { API } from 'aws-amplify';
import { OkrContext } from "../../contexts/okr-context"
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  useAuthenticator } from '@aws-amplify/ui-react';
import React from "react";
import SettingsIcon from '@mui/icons-material/Settings';
import { createBoard, createOkrStatus, deleteBoard, createOkrGraph } from "../../graphql/mutations";
import { listBoards, listUserTours } from "../../graphql/queries";
import { Button, TextField } from "@mui/material";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Link } from "react-router-dom";

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'Board', label: 'Board', minWidth: 170 },
  { id: 'Actions', label: 'Actions', minWidth: 170, align: 'right' },
];

export default function Dashboard() {

  const {state, dispatch} = useContext(OkrContext)
  const [page, setPage] = useState(0);
  const [isAddBoardOpen, setIsAddBoardOpen] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    const fetchBoards = async () => {
      const boards = await API.graphql({
        query: listBoards,
        variables: { limit: 10000 }
      }) as any
      dispatch({
        type: 'setBoards',
        payload: {
          boardList: boards.data.listBoards.items
        }
      })
    }
    const fetchTour = async () => {
      const boards = await API.graphql({
        query: listUserTours,
        variables: { limit: 10000 }
      }) as any
      dispatch({
        type: 'setBoards',
        payload: {
          boardList: boards.data.listBoards.items
        }
      })
    }
    fetchBoards()
    dispatch({
      type: 'resetStateForDashboard'
    })
  }, [])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <AddBoardDialog open={isAddBoardOpen} closeFunction={setIsAddBoardOpen}></AddBoardDialog>
      <Button id="create-board" variant="contained"  style={{ margin: 8, float: 'right'}} onClick={() => {
        setIsAddBoardOpen(true)
      }}>Create New</Button>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.boardList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((board) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={board.id}>
                      <TableCell>  <Link to={`/app/graph/${board.friendlyId}`}>{board.title}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          <Link to={`/app/graph/${board.friendlyId}`}><AccountTreeOutlinedIcon></AccountTreeOutlinedIcon></Link>
                          <Link to={`/app/calendar/${board.friendlyId}`}><CalendarMonthOutlinedIcon></CalendarMonthOutlinedIcon></Link>
                          <Link to={`/app/settings/${board.friendlyId}`}><SettingsIcon></SettingsIcon></Link>
                          {/* <Button onClick={() => {
                            API.graphql({
                              query: deleteBoard,
                              variables: { input: { id: board.id } }
                            })
                          }}><DeleteIcon color="warning"></DeleteIcon></Button> */}
                        </div>
                      </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={state.boardList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}


interface AddBoardDialogProps {
  open: boolean
  closeFunction: Dispatch<SetStateAction<boolean>>
}

function AddBoardDialog(props: AddBoardDialogProps) {
  const {state, dispatch} = useContext(OkrContext)
  const [boardTitle, setBoardTitle] = useState('');
  const [boardFriendlyId, setBoardFriendlyId] = useState('')

  const { user } = useAuthenticator((context) => [context.user]);

  const handleClose = () => {
    props.closeFunction(false);
  };

  const handleSave = async () => {
    const boardResult = await API.graphql({
      query: createBoard,
      variables: {
        input: {
          title: boardTitle,
          friendlyId: boardFriendlyId,
          groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
        }
      }
    }) as any

    const graphResponse = await API.graphql({
      query: createOkrGraph,
      variables: {
          input: {
            boardId: boardResult.data.createBoard.id,
            nodes: "[]",
            edges: "[]",
            groupAccess: user.getSignInUserSession()?.getAccessToken().payload['cognito:groups'][0]
          }
      }
    })

    dispatch({
      type: 'addBoard',
      payload: {
        ...state,
        board: boardResult.data.createBoard
      }
    })

    const boardId = boardResult.data.createBoard.id

    props.closeFunction(false);
  }

  return (
      <Dialog

        open={props.open}
        onClose={handleClose}
        aria-labelledby="AddBoardDialogProps-title"
        aria-describedby="AddBoardDialogProps-description"
      > <div id="create-board-dialog">
        <DialogTitle id="AddBoardDialogProps-title">
          {"Create New Board"}
        </DialogTitle>
        <DialogContent sx={{display: 'flex', flexDirection:'column'}}>
          <TextField id="create-board-title" sx={{margin: "8px 0"}} label="Title" value={boardTitle} onChange={(event) => { setBoardTitle(event.target.value)}}></TextField>
          <TextField
            id="create-board-friendly"
            sx={{margin: "4px 0"}}
            label="Friendly Id"
            value={boardFriendlyId}
            onChange={(event) => { setBoardFriendlyId(event.target.value)}}
            onKeyDown={(event => {
              const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9-]+$/
              if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                event.preventDefault();
              }
            })}
            inputProps={{
              style: { textTransform: "uppercase" },
              maxLength: 5
            }}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button id="save-new-board" onClick={handleSave} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
        </div>
      </Dialog>
  );
}