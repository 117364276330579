import React, { useContext } from "react";
import { OkrContext } from "../contexts/okr-context";
import { Link } from "react-router-dom";

export default function Notifications() {
    const {state, dispatch} = useContext(OkrContext)
    if (state.isDemo) {
        return <div style={{      
          background: 'lightblue',
          borderRadius: '4px',
          padding: '4px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <Link to="/nav/contact">Notifications are not enabled for your account. Click <span style={{textDecoration: 'underline'}}>HERE</span> to upgrade.</Link>
        </div>
    }
    return <>
    
    </>
}