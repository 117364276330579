import { useContext, useEffect, useState } from 'react'
import { useSearchParams  } from "react-router-dom";
import { styled } from '@mui/system';
import moment from 'moment';
import { listOkrItems, listOkrStatuses } from '../../graphql/queries';
import { API } from 'aws-amplify';
import { CalendarContext } from './calendar-dash';
import { OkrContext } from '../../contexts/okr-context.tsx';
import EditOkrDialog from '../edit-okr-dialog'

const CalendarContainer = styled('div')({
    border: 'solid 1px #dbdce3',
    padding: 8,
    borderRadius: 4,
    display: 'flex',
    flexWrap: 'wrap',
})
// TODO: copy google and make row containers with 7 days each
const CalendarDay = styled('div')({
    flex: '1 0 14%',
    border: 'solid 1px #dbdce3',
    // height: '16vh',
    width: '10vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
})

const CalendarDate = styled('div')({

})

const OkrTile = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: 'solid 2px lightblue',
    borderRadius: '4px',
    width: '100%',
    cursor: 'pointer'
})

const OkrTileIcon = styled('p')((props) => ({
    width: '16px',
    height: '16px',
    borderRadius: '50%',
    background: `conic-gradient(${props.status} ${props.completion}%, ${props.statusLo} ${props.completion}%) border-box`,
    margin: 4
}))


const stringToColor = (string) => {
	const color = JSON.parse(string)
	return `rgba(${color.r},${color.g},${color.b},${color.a})`
}


export default function Calendar(props) {
    const {state, dispatch} = useContext(OkrContext)
    const calendarContext = useContext(CalendarContext)
    const [ okrsInView, setOkrsInView ] = useState({})

    // ************************ initial load, move this **************


  useEffect(() => {
    if (!state.currentBoard) { return }
    const getOkrItemList = async () => {
      let okrItemResponse = await API.graphql({
        query: listOkrItems,
        variables: {
          filter: {
            boardId: { eq: state.currentBoard.id }
          },
          limit: 10000
        }
      })
      dispatch({
        type: 'setOkrItemList',
        payload: {
          okrList: okrItemResponse.data.listOkrItems.items
        }
      })

    }
    const getOkrItemStatus = async () => {
      let okrItemResponse = await API.graphql({
        query: listOkrStatuses,
        variables: {
          limit: 10000
        }
      })
      dispatch({
        type: 'setOkrStatusOptions',
        payload: {
          okrStatusOptions: okrItemResponse.data.listOkrStatuses.items
        }
      })

    }

    getOkrItemList()
    getOkrItemStatus()
  }, [state.currentBoard?.id])

  // *********************************** END ***************

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect((effect, deps) => {
        const okrItem = state.okrList.find(so => so.boardId === searchParams.get('edito'))
        if (okrItem) {
            dispatch({
                type: 'setOkrEditIsOpen',
                payload: {
                    isEditOkrOpen: true,
                    id: okrItem.id
                }
            })
        }
    }, [searchParams.get('edito')])

    useEffect(() => {
        // a, b are momentjs items
        const orderedOkrList = [...state.okrList]
        const newOkrsInView = {}
        orderedOkrList.sort((a, b) => {
            if (moment(a.endDate).isAfter(moment(b.endDate))) return 1
            if (moment(b.endDate).isAfter(moment(a.endDate))) return -1
            return 0
        })
        const start = calendarContext.daysInView[0]
        const end = calendarContext.daysInView[calendarContext.daysInView.length - 1]
        calendarContext.daysInView.forEach(day => {
            newOkrsInView[day.format('YYYY-MM-DD')] = []
        })

        orderedOkrList.forEach(okrItem => {
            const date = moment(okrItem.endDate)
            //TODO: make this more efficient
            if ( (date.isSame(start) || date.isAfter(start))
                || (date.isSame(end) || date.isBefore(end)) ) {
                    if (newOkrsInView[date.format('YYYY-MM-DD')]) { //TODO don't query all items, just those in view
                        newOkrsInView[date.format('YYYY-MM-DD')].push(okrItem)
                    }
            }
        })
        setOkrsInView(newOkrsInView)

    }, [state.okrList])

    return (
        <CalendarContainer id='calendar-container'>
            {calendarContext.daysInView.map(i => {
                return <CalendarDay
                className={`day-${i.day()}`}
                    key={`day-${i.format('YYYY-MM-DD')}`}
                    onClick={() => {
                        dispatch({
                            type: 'setOkrEditIsOpen',
                            payload: {
                                isEditOkrOpen: true,
                                editOkrId: false
                            }
                        })
                        dispatch({
                            type: 'setEditOkrDialogDate',
                            payload: {
                                date: i.format('YYYY-MM-DD')
                            }
                        })
                    }}>
                    <CalendarDate >{i.date()}</CalendarDate>
                    <div className={`calendar-day`} style={{width: '100%', padding: '2px 4px'}}>
                        {okrsInView[i.format('YYYY-MM-DD')]?.map(okrItem => {

                            const status = state.okrStatusOptions.find(bo => bo.id === okrItem?.okrStatusId)
                            || state.okrStatusOptions.sort((ba, bb) => ba.order - bb.order)[0]
                            if (!status) {
                                return <></>
                            }

                            return (
                                <OkrTile
                                    key={`tile-${okrItem.id}`}
                                    onClick={(event) => {
                                        event.preventDefault()
                                        event.stopPropagation()
                                        dispatch({
                                            type: 'setOkrEditIsOpen',
                                            payload: {
                                                isEditOkrOpen: true,
                                                id: okrItem.id
                                            }
                                        })
                                        setSearchParams(`?edito=${okrItem.boardId}`)
                                }}>
                                    <OkrTileIcon status={stringToColor(status.color)} statusLo={stringToColor(status.lolightColor)} completion={okrItem.completion}></OkrTileIcon>
                                    <span className='calendar-day-text'>{okrItem.title}</span>
                                </OkrTile>
                            )
                        })}
                    </div>
                </CalendarDay>
            })}
            {state.isEditOkrOpen && <EditOkrDialog></EditOkrDialog>}
        </CalendarContainer>
    )

}

