import React, { memo, useState, useContext, useCallback } from 'react';
import _debounce from 'debounce';
import { NodeResizer } from '@reactflow/node-resizer';
import './group-node.css'
import { TextField } from '@mui/material';
import { OkrContext } from '../../../contexts/okr-context';

//TODO on drag start, make avaialable targets light up
export default memo((props) => {
  const { state, dispatch } = useContext(OkrContext);
  const graphNode = state.flow.nodes.find(node => node.id === props.id)
  const [groupName, setGroupName] = useState(props.data.label)
  const updateNodes = useCallback(_debounce(nodes => {
    props.data.setNodes(nodes)
    dispatch({
      type: 'setNodes',
      payload: {
        nodes: nodes
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [props])
  return (
    <>
    <NodeResizer handleClassName="node-resize" className='group-resizer'  isVisible={props.selected} minWidth={100} minHeight={30} />
		<div className='group-node' >
			<div className="group-node-name"><TextField
        value={groupName}
        onChange={(event) => {
          setGroupName(event.target.value)
          graphNode.data.label = event.target.value
          const newNodes = [...state.flow.nodes]
          newNodes[state.flow.nodes.indexOf(graphNode)] = graphNode
          updateNodes(state.flow.nodes)
        }}
      ></TextField></div>
        </div>
    </>
  );
});