import React, { useContext } from "react";
import { OkrContext } from "../contexts/okr-context";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { useAuthenticator } from '@aws-amplify/ui-react';

export default function Account() {
    const {state, dispatch} = useContext(OkrContext)
  
    const { user, signOut, authStatus } = useAuthenticator((context) => [context.user]);

    return <>
      <div className="user-profile">
        <div className="user-options">
          <div className="user-name">{state.currentUser.name}</div>
          <Button onClick={signOut}>Sign Out</Button>
        </div>
        <div className="password-reset"></div>
      </div>

      <div className="account-container">
        <div className="users">
          
        </div>
      </div>
    </>
}