import React, { useCallback, useRef, useState } from "react";
import { RgbaColorPicker, RgbaColor } from "react-colorful";
import { useDebouncedCallback  } from 'use-debounce';

import useClickOutside from "./useClickOutside";

interface PopoverPickerInterface {
    color: string;
    onChange: (val: any) => void
}

export default function PopoverPicker ({ color, onChange }: PopoverPickerInterface) {
  const popover = useRef(null);
  const [isOpen, toggle] = useState(false);
  const rgbaColor = JSON.parse(color) as RgbaColor

  const close = useCallback(() => toggle(false), []);
  useClickOutside(popover, close);
  const handleChange = useDebouncedCallback(
    (val: RgbaColor) => {
        onChange(JSON.stringify(val))
        toggle(false)
  }, 1000)

  return (
    <div className="picker">
      <div
        className="swatch"
        style={{ background: `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b},${rgbaColor.a})`, height: '24px', width: '24px', cursor: 'pointer', borderRadius: '4px' }}
        onClick={() => toggle(true)}
      />

      {isOpen && (
        <div className="popover" ref={popover} style={{position: 'absolute'}}>
          <RgbaColorPicker color={rgbaColor} onChange={(val) => {
            handleChange(val)
          }} />
        </div>
      )}
    </div>
  );
};
