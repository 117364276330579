/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      name
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      accountId
      name
      email
      cognitoId
      avatarColor
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        name
        email
        cognitoId
        avatarColor
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getKeyResult = /* GraphQL */ `
  query GetKeyResult($id: ID!) {
    getKeyResult(id: $id) {
      id
      parent_id
      boardId
      accountId
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listKeyResults = /* GraphQL */ `
  query ListKeyResults(
    $filter: ModelKeyResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listKeyResults(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parent_id
        boardId
        accountId
        text
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getFlowNode = /* GraphQL */ `
  query GetFlowNode($id: ID!) {
    getFlowNode(id: $id) {
      id
      position
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listFlowNodes = /* GraphQL */ `
  query ListFlowNodes(
    $filter: ModelFlowNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFlowNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        position
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      title
      color
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        color
        accountId
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOkrStatus = /* GraphQL */ `
  query GetOkrStatus($id: ID!) {
    getOkrStatus(id: $id) {
      id
      label
      color
      lolightColor
      order
      accountId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOkrStatuses = /* GraphQL */ `
  query ListOkrStatuses(
    $filter: ModelOkrStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOkrStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        color
        lolightColor
        order
        accountId
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOkrItem = /* GraphQL */ `
  query GetOkrItem($id: ID!) {
    getOkrItem(id: $id) {
      id
      title
      okrStatusId
      linkedOkrs
      completion
      isCompletionCalculated
      endDate
      createdBy
      assignedTo
      groups
      tags
      boardId
      boardFriendlyId
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOkrItems = /* GraphQL */ `
  query ListOkrItems(
    $filter: ModelOkrItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOkrItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        okrStatusId
        linkedOkrs
        completion
        isCompletionCalculated
        endDate
        createdBy
        assignedTo
        groups
        tags
        boardId
        boardFriendlyId
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      title
      accountId
      friendlyId
      endDate
      nextFriendlyId
      status
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        accountId
        friendlyId
        endDate
        nextFriendlyId
        status
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getIntegration = /* GraphQL */ `
  query GetIntegration($id: ID!) {
    getIntegration(id: $id) {
      id
      accountId
      type
      title
      config
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listIntegrations = /* GraphQL */ `
  query ListIntegrations(
    $filter: ModelIntegrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIntegrations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        type
        title
        config
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOkrGraph = /* GraphQL */ `
  query GetOkrGraph($id: ID!) {
    getOkrGraph(id: $id) {
      id
      boardId
      edges
      nodes
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOkrGraphs = /* GraphQL */ `
  query ListOkrGraphs(
    $filter: ModelOkrGraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOkrGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        boardId
        edges
        nodes
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOkrComment = /* GraphQL */ `
  query GetOkrComment($id: ID!) {
    getOkrComment(id: $id) {
      id
      okrId
      createdBy
      text
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOkrComments = /* GraphQL */ `
  query ListOkrComments(
    $filter: ModelOkrCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOkrComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        okrId
        createdBy
        text
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getOkrTag = /* GraphQL */ `
  query GetOkrTag($id: ID!) {
    getOkrTag(id: $id) {
      id
      accountId
      text
      color
      groupAccess
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listOkrTags = /* GraphQL */ `
  query ListOkrTags(
    $filter: ModelOkrTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOkrTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        accountId
        text
        color
        groupAccess
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCandidate = /* GraphQL */ `
  query GetCandidate($id: ID!) {
    getCandidate(id: $id) {
      id
      name
      email
      createdAt
      updatedAt
    }
  }
`;
export const listCandidates = /* GraphQL */ `
  query ListCandidates(
    $filter: ModelCandidateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCandidates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserTour = /* GraphQL */ `
  query GetUserTour($id: ID!) {
    getUserTour(id: $id) {
      tourSteps
      id
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserTours = /* GraphQL */ `
  query ListUserTours(
    $filter: ModelUserTourFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTours(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        tourSteps
        id
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
