import React, { useState, useContext, useEffect, useCallback } from "react"
import _debounce from 'debounce';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { OkrContext } from "../../contexts/okr-context"
import DeleteIcon from '@mui/icons-material/Delete';
import ColorPicker from './ColorPicker'
import Button from "@mui/material/Button/Button";
import { OkrStatus } from "../../contexts/okr-context";
import { RgbaColor } from "react-colorful";
import { updateOkrStatus, deleteOkrStatus, createOkrStatus } from "../../graphql/mutations";
import { listOkrStatuses } from "../../graphql/queries";
import {  useAuthenticator } from '@aws-amplify/ui-react';
import { API } from "aws-amplify";


export default function BoardSettings() {
    const {state, dispatch} = useContext(OkrContext)
    const [deleting, setDeleting] = useState<string[]>([])
    const { user } = useAuthenticator((context) => [context.user]);

    useEffect(() => {
    }, [])


    const saveStatus = useCallback(_debounce(async (input: OkrStatus) => {
        const status = await API.graphql({
          query: updateOkrStatus,
          variables: {
              input : {
                color: input.color,
                id: input.id,
                label: input.label,
                lolightColor: input.lolightColor,
                order: input.order
              }
          }
        })
      }), [])

    const updateStatus = (status: OkrStatus) => {
        dispatch({
            type: 'editOkrStatus',
            payload: {
                status
            }
        })
        saveStatus(status)
    }

    useEffect(() => {
        const getOkrItemStatus = async () => {
            if (!state.currentBoard) { return }
            let okrItemResponse = await API.graphql({
              query: listOkrStatuses,
              variables: {
                limit: 10000
              }
            }) as any

            dispatch({
              type: 'setOkrStatusOptions',
              payload: {
                okrStatusOptions: okrItemResponse.data.listOkrStatuses.items
              }
            })
          }
          getOkrItemStatus()
    }, [state.currentBoard?.id])

    return  (
    <div>
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Status Label</TableCell>
                        <TableCell align="left">Status Color</TableCell>
                        <TableCell align="left">Status Secondary Color</TableCell>
                        <TableCell align="left">Order</TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {state.okrStatusOptions.sort((a,b) => {
                    if (a.order > b.order) return 1
                    if (a.order < b.order) return -1
                    return 0
                }).map((status) => (
                    <TableRow
                    key={status.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell align="left">
                        <TextField
                            variant="standard"
                            value={status.label}
                            onChange={(event) => {
                                status.label = event.target.value
                                updateStatus(status)
                            }}
                        ></TextField>
                    </TableCell>
                    <TableCell align="left"><ColorPicker color={status.color} onChange={(val: any) => {
                        const newStatus = { ...status }
                        newStatus.color = val
                        updateStatus(newStatus)
                    }}></ColorPicker></TableCell>
                    <TableCell align="left"><ColorPicker color={status.lolightColor} onChange={(val: any) => {
                        const newStatus = { ...status }
                        newStatus.lolightColor = val
                        updateStatus(newStatus)
                    }}></ColorPicker></TableCell>
                    <TableCell align="left">{status.order}</TableCell>
                    <TableCell align="right">
                        <Button onClick={async () => {
                            let newDeleting = [...deleting]
                            newDeleting.push(status.id)
                            setDeleting(newDeleting)
                            await API.graphql({
                              query: deleteOkrStatus,
                              variables: { input: { id: status.id } }
                            })


                            let options = [...state.okrStatusOptions]
                            const deleted = options.find(o => o.id === status.id)
                            if (!deleted) {return}

                            options.splice(options.indexOf(deleted), 1)
                            dispatch({
                                type: 'setOkrStatusOptions',
                                payload: {
                                okrStatusOptions: options
                                }
                            })

                          }}>{deleting.includes(status.id) ? <CircularProgress />
                          : <DeleteIcon color="warning"></DeleteIcon>}</Button>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    )
}

