import React, { createContext, useReducer } from "react"
import { v4 as uuid } from 'uuid';
const tour = require('./shepherd-tour')

export interface OkrItem {
  tempId?: any;
  id?: string;
  linkedOkrs?: Array<string>;
  title: string;
  status: OkrStatus;
  endDate: any; //TODO: better dates
  createdBy: string; //TODO: user
  assignedTo: string; //TODO: user
  groups: Array<string>; //TODO: group
  completion: number;
  isCompletionCalculated: boolean;
  boardId: string;
  boardFriendlyId?: string;
  okrStatusId?: string;
  tags: Array<string>;
}
// Amazon S3 Buckets amplify-amplifyb794e61ce5a04-staging-170550-deployment deployment-state.json
export interface OkrStatus {
  order: number;
  label: string;
  color: string;
  lolightColor: string;
  id: string;
}

export interface User {
  id: string;
  name: string;
  email?: string;
  avatar?: string;
  avatarColor?: string;
}

export interface Comment {
  id: string;
  okrId: string;
  createdBy: string;
  text: string;
  createdAt?: string;
}

interface OkrContextInterface {
  okrList: Array<OkrItem>;
  groupList: Array<Group>;
  boardList: Array<Board>;
  tagList: Array<Tag>;
  okrStatusOptions: Array<OkrStatus>;
  integrationList: Array<Integration>;
  isToastOpen: boolean;
  isEditOkrOpen: boolean;
  editOkrId: string;
  editOkrIntegrations: any;
  parentOkrId: string;
  account: Account;
  currentBoard?: Board;
  editOkrDialogDate?: string;
  users: Array<User>;
  currentUser: User;
  comments: Array<Comment>;
  editingIntegration?: Integration;
  //Graph context TODO move to other context
  flow: Flow;
  connectingOkr?: any;

  //TODO hack needs back end solution
  isDemo?: boolean;
  redirectQueryString?: string;

  //other
  tourGuide?: any;

}

interface Flow { //maps to OkrGraph
  edges: any;
  nodes: any;
  editNode: any; //not a model prop
  connectingNodeId: any; //not a model prop
  id?: string;
}

interface OkrAction {
  type: string;
  payload: any;
}

export interface Group {
  title: string;
  color: string;
  id: string;
}

export interface Tag {
  text: string;
  color: string;
  id: string;
}

interface Board {
  endDate: any;
  title: string;
  friendlyId: string;
  id: string;
}

interface Account {
  title: string;
  id: string;
}

export interface Integration {
  id: string;
  type: string;
  title: string;
  config: any;
}


const initalState = {
  isDemo: false,
  okrList: [
    // {
    //   id: uuid(),
    //   linkedOkrs: ['1'],
    //   title: 'title-1',
    //   status: {label: 'Green', color: 'green', lolightColor: 'rgba(200,255,200,1)' } as OkrStatus,
    //   endDate: moment('2023-05-22'),
    //   createdBy: 'user-1',
    //   assignedTo: ['user-1'],
    //   groups: ['group-1'],
    //   completion: 30,
    //   isCompletionCalculated: false,
    //   boardId: 'AA-1'
    // },
  ],
  groupList: [],
  boardList: [],
  isToastOpen: false,
  isEditOkrOpen: false,
  okrStatusOptions: [],
  integrationList: [{
    id: uuid(),
    type: 'Jira',
    title: 'Jira Context Delete',
    config: {
      token: 'blarg'
    }
  }],
  editOkrId: '0',
  editOkrIntegrations: [],
  parentOkrId: '',
  account: {
    id: '1',
    title: 'Demo Account'
  },
  flow: {
    edges: [],
    nodes: [],
    editNode: {},
    connectingNodeId: ''
  },
  nextBoardId: 0,
  editOkrDialogDate: '',
  tagList: [],
  users: [],
  currentUser: {} as User,
  comments: [],
  tourGuide: {
    tour,
    userNeedsTour: true
  },
  editingIntegration: {
    title: 'TODO remove title',
    id: uuid(),
    type: 'jira',
    config: {}
  }
}

export const OkrContext = createContext<{
    state: OkrContextInterface,
    dispatch: React.Dispatch<any>
  }>({
    state: initalState,
    dispatch: () => { }
});

const reducer = (state: any, action: OkrAction) => {
  switch(action.type) {
    case "addOkrItem": {
      const newOkrList = [...state.okrList]
      const newOkr = action.payload.okrItem
      //TODO get id
      newOkrList.push(newOkr)
      return {
        ...state,
        okrList: newOkrList
      }
    }
    case "resetStateForDashboard": {
      return {
        ...state,
        flow: {
          nodes: [],
          edges: []
        },
        currentBoard: {},
        okrList: [],
        groupList: [],
        tagList: []
      }
    }
    case "editOkrItem": {
      const newOkrList = [...state.okrList]
      let okrToUpdate = newOkrList.find(okr => okr.id === action.payload.id)
      const index = newOkrList.indexOf(okrToUpdate)
      okrToUpdate = {
        ...okrToUpdate,
        ...action.payload
      }
      if (index !== -1) {
        newOkrList[index] = okrToUpdate
      } else {
        newOkrList.push(okrToUpdate)
      }

      return {
        ...state,
        okrList: newOkrList
      }
    }
    case "linkKrToOkr": {
      const newOkrList = [...state.okrList]
      let okrToUpdate = newOkrList.find(okr => okr.id === action.payload.id)
      const index = newOkrList.indexOf(okrToUpdate)
      if (!okrToUpdate) {
        return { ...state }
      }
      if (!okrToUpdate.linkedOkrs) {
        okrToUpdate.linkedOkrs = []
      }
      okrToUpdate = {
        ...okrToUpdate,
        linkedOkrs: [
          ...okrToUpdate.linkedOkrs,
          action.payload.okrToLinkId
        ]
      }
      if (index !== -1) {
        newOkrList[index] = okrToUpdate
      } else {
        newOkrList.push(okrToUpdate)
      }
      return {
        ...state,
        okrList: newOkrList
      }
    }
    case "closeToast": {
      return {
        ...state,
        isToastOpen: false
      }
    }
    case "setBoards": {
      return {
        ...state,
        boardList: action.payload.boardList
      }
    }
    case "setIntegrations": {
      return {
        ...state,
        integrationList: action.payload.integrationList
      }
    }
    case "setOkrItemList": {
      return {
        ...state,
        okrList: action.payload.okrList
      }
    }
    case "setEditOkrId": {
      return {
        ...state,
        editOkrId: action.payload.id
      }
    }
    case "setOkrEditIsOpen": {
      return {
        ...state,
        isEditOkrOpen: action.payload.isEditOkrOpen,
        editOkrId: action.payload.id
      }
    }
    case "setAccount": {
      return {
        ...state,
        account: action.payload.account
      }
    }
    case "setParentOkrId": {
      return {
        ...state,
        parentOkrId: action.payload.parentOkrId
      }
    }
    case "setNodes": { //flow context should be moved
      return {
        ...state,
        flow: {
          ...state.flow,
          nodes: action.payload.nodes
        }
      }
    }
    case "setEdges": {
      return {
        ...state,
        flow: {
          ...state.flow,
          edges: action.payload.edges
        }
      }
    }
    case "addNode": {
      const nodes = [...state.flow.nodes]
      nodes.push(action.payload.node)
      return {
        ...state,
        flow: {
          ...state.flow,
          nodes
        }
      }
    }
    case "addEdge": {
      const newEdge = action.payload.edge
      if (state.flow.edges.find((e: any) => e.source === newEdge.source && newEdge.target === e.target)) {
        return state
      }
      const edges = [...state.flow.edges]
      edges.push (action.payload.edge)
      return {
        ...state,
        flow: {
          ...state.flow,
          edges
        }
      }
    }
    case "addToGroup": {
      return {
        ...state
      }
    }
    case "setEditNode": {
      return {
        ...state,
        flow: {
          ...state.flow,
          editNode: action.payload.editNode
        }
      }
    }
    case "editOkrStatus": {
      const statuses = [...state.okrStatusOptions]
      const status = statuses.find((s: OkrStatus) => s.id === action.payload.status.id)
      const index = statuses.indexOf(status)
      statuses[index] = action.payload.status
      return {
        ...state,
        okrStatusOptions: statuses
      }
    }
    case "setOkrStatusOptions": {
      if (action.payload.okrStatusOptions.length === 0) { return state} //TODO remove hack
      return {
        ...state,
        okrStatusOptions: action.payload.okrStatusOptions
      }
    }
    case "setCurrentBoardByFriendlyId": {
      const board = state.boardList.find((b: Board) => b.friendlyId === action.payload.boardFriendlyId)
      return {
        ...state,
        currentBoard: board
      }
    }
    case "setCurrentBoard": {
      return {
        ...state,
        currentBoard: action.payload.board
      }
    }
    case "setFlow": {
      const graph = action.payload.graph
      return {
        ...state,
        flow: {
          ...state.flow,
          id: graph.id,
          edges: graph.edges,
          nodes: graph.nodes
        }
      }
    }
    case "advanceBoardFriendlyId": {
      return {
        ...state,
        nextBoardId: state.nextBoardId + 1
      }
    }
    case "setConnectingOkr": {
      return {
        ...state,
        connectingOkr: action.payload.connectingOkr
      }
    }
    case "setEditOkrDialogDate": {
      return {
        ...state,
        editOkrDialogDate: action.payload.date
      }
    }
    case "setGroups": {
      return {
        ...state,
        groupList: action.payload.groupList
      }
    }
    case "setTags": {
      return {
        ...state,
        tagList: action.payload.tags
      }
    }
    case "addTag": {
      return {
        ...state,
        tagList: [...state.tagList].concat(action.payload.tag)
      }
    }
    case "addGroup": {
      return {
        ...state,
        groupList: [...state.groupList].concat(action.payload.group)
      }
    }
    case "addBoard": {
      return {
        ...state,
        boardList: [...state.boardList].concat(action.payload.board)
      }
    }
    case "setUsers": {
      return {
        ...state,
        users: action.payload.users
      }
    }
    case "setCurrentUser": {
      return {
        ...state,
        currentUser: action.payload.user
      }
    }
    case "setOkrComments": {
      return {
        ...state,
        comments: action.payload.comments
      }
    }
    case "updateNeedsTour": {
      return {
        ...state,
        tourGuide: {
          ...state.tourGuide,
          userNeedsTour: action.payload.needsTour
        }
      }
    }
    case "setRedirectQueryString": {
      return {
        ...state,
        redirectQueryString: action.payload.redirectQueryString
      }
    }
    case "setEditingIntegrationTitle": {
      return {
        ...state,
        editingIntegration: {
          ...state.editingIntegration,
          title: action.payload.title
        }
      }
    }
    case "setEditingIntegration": {
      return {
        ...state,
        editingIntegration: action.payload.integration
      }
    }
    case "setIntegrationList": {
      return {
        ...state,
        integrationList: action.payload.integrationList
      }
    }
    case "setEditingIntegrationConfig": {
      return {
        ...state,
        editingIntegration: {
          ...state.editingIntegration,
          config: action.payload.config
        }
      }
    }
    default:
      return state
  }
}

interface ArtProviderProps {
  children: React.ReactNode;
}

export const OkrProvider = ({ children }: ArtProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initalState)

    return (
        <OkrContext.Provider value={{state, dispatch}}>
            {children}
        </OkrContext.Provider>
    )
}

