import React from "react";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import { Routes, Route, useLocation, Navigate, Outlet, useSearchParams } from "react-router-dom";
import "./App.css";
import CalendarDash from "./components/calendar/calendar-dash";
import { Login } from './components/login/index'
import GraphBuilder from "./components/okr/graph/graph-builder";
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { OkrProvider } from "./contexts/okr-context.tsx";
import SplashPage from "./components/home/splash.tsx";
import DashSideNav from "./components/dashboard/dash-side-nav.jsx"
import Dashboard from "./components/dashboard/dashboard";
import Integrations from "./components/integrations/integrations-page"
import Base from "./components/home/base"
import Pricing from "./components/home/pricing"
import ContactPage from "./components/home/contact"
import BoardSettings from "./components/dashboard/board-settings"
import Account from "./components/Account"
import Goals from "./components/Goals"
import Groups from "./components/Groups"
import Notifications from "./components/Notifications"
import JiraAuth from "./components/integrations/jira-auth"
import JiraIntegration from "./components/integrations/jira-integration"


import '@aws-amplify/ui-react/styles.css';
Amplify.configure(awsconfig);

class App extends React.Component {

  state = {}

  render() {
    return (
      <Authenticator.Provider>
        <OkrProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login></Login>}></Route>
          <Route path="calendar/*" element={<RequireAuth><CalendarDash /></RequireAuth>} />
          <Route path="graph" element={<RequireAuth><GraphBuilder/></RequireAuth>} />
          <Route path="nav" element={<Base></Base>}>
            <Route index element={<SplashPage></SplashPage>}></Route>
            <Route path="pricing" element={<Pricing></Pricing>}></Route>
            <Route path="contact" element={<ContactPage></ContactPage>}></Route>
          </Route>
          <Route element={<RequireAuth></RequireAuth>}>
            <Route path="app" element={<DashSideNav/>}>
              <Route path="dashboard" element={<Dashboard/>}></Route>
              <Route path="integrations/jira/state/*/code/*" element={<JiraAuth></JiraAuth>}></Route>
              <Route path="integrations/jira/:id/:title" element={<JiraIntegration></JiraIntegration>}></Route>
              <Route path="integrations/jira" element={<JiraAuth></JiraAuth>}></Route>
              <Route path="integrations" element={<Integrations/>}></Route>
              <Route path="calendar/*" element={<CalendarDash/>}></Route>
              <Route path="calendar/*/" element={<CalendarDash/>}></Route>
              <Route path="calendar/<*>/" element={<CalendarDash/>}></Route>
              <Route path="graph/*" element={<GraphBuilder/>}></Route>
              <Route path="settings/*" element={<BoardSettings/>}></Route>
              <Route path="goals/*" element={<Goals/>}></Route>
              <Route path="groups/*" element={<Groups/>}></Route>
              <Route path="account/*" element={<Account/>}></Route>
              <Route path="notifications/*" element={<Notifications/>}></Route>
            </Route>
          </Route>
        </Routes>
      </OkrProvider>
      </Authenticator.Provider>
    );
  }

}
let search = ''
//The auth redirect strips the search params, and there's not a non hack way to fix it
export function RequireAuth({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route === 'idle' && location.search !== '') {
    search = location.search
  }
  if (route !== 'authenticated') {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if(search.length > 0) {
    window.setTimeout(() => { setSearchParams(search)
      search = ''
    }, 300)
  }
  return <Outlet></Outlet>;
}

// App.js
function Home() {
  return (
    <>
      <Base></Base>
      <SplashPage></SplashPage>
    </>
  );
}

export default App;
