import * as React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import './splash.css'
import Footer from './footer';
const demoCalImage = require('../../images/demoCal2.png')
const demoFlowImage = require('../../images/demoFlow3.png')
const salesforceLogo = require('../../images/salesforce-logo.png')
const jiraLogo = require('../../images/jira-align-logo.png')
const mondayLogo = require('../../images/monday-logo.png')
const ZendeskLogo = require('../../images/zendesk-1.png')
const alertImage = require('../../images/connect-alert.png')
function SplashPage() {
  return (<>
    <div className='splash-container'>
    <div style={{margin: '20px 28px', display: 'flex', justifyContent: 'center'}} className='splash-section splash-item'>
        <div style={{display: 'flex', flexDirection: 'column', flex: '1 1 40vw'}}>
          <div style={{fontSize: '72px', lineHeight: '72px'}}>Goal alignment for your whole company</div>
          <div style={{marginTop: '16px'}}>Drive alignment while encouraging employees to maintain their voice
            <div></div>
            {/* <Link style={{textDecoration: 'underline'}} to='/app/Dashboard'>Build</Link> your first cross functional flow in our demo app */}
            </div>
          <div>
            <Link to="/app/Dashboard"><Button variant='contained' sx={{margin: '12px 12px 12px 0px'}}>Start free trial</Button></Link>
            <Link to="/nav/contact"><Button variant='outlined' sx={{margin: '12px 12px 12px 0px'}}>Contact Us</Button></Link>
          </div>
        </div>
        <div className='image-box'><img src={demoFlowImage} style={{height: '33vh'}} /></div>
    </div>

    <div style={{margin: '20px 28px', height: '40vh', display: 'flex', justifyContent: 'center'}} className='splash-section2'>
      <div className="splash-item" id="si-2" style={{display: 'flex', flexDirection: 'row', flex: '1 1 40vw'}}>
        <div className='image-box' style={{background: '#00afaf', margin: '24px'}}><img src={demoCalImage} style={{height: '33vh', margin: '24px'}} /></div>
        <div style={{zIndex:100}}>
          <div style={{fontSize: '32px', lineHeight: '32px', zIndex: 100, margin: '24px'}}>Make the most of your goals with Calendars to keep you on track</div>

          <div style={{fontSize: '24px', lineHeight: '24px', zIndex: 100, margin: '24px'}}>
            Don't let a deadline creep up on you. With Calendars you can see your goals at a glance and know immediately which ones are at risk. Without any extra meetings.
          </div>
        </div>
      </div>
    </div>


    <div style={{margin: '20px 28px', height: '40vh', display: 'flex', justifyContent: 'center'}} className='splash-section2 splash-item'>
      <div className="splash-item" style={{zIndex: 10}}>
        <div style={{fontSize: '32px', lineHeight: '32px', zIndex: 100}}>
          <div style={{margin: '16px'}}>Stay on track by connecting to Slack or Email with customized notifications</div>
          <div style={{fontSize: '24px', lineHeight: '24px', zIndex: 100, margin: '16px'}}>
            Be alerted when your goals are at risk. Integrating OKR Calnedar with your email and chat tools keeps your team on the same page and on track.
          </div>
        </div>
      </div>
      <div className='image-box' id="alert-image" style={{position: 'relative', zIndex: 100, padding: 12}}><img src={alertImage} style={{height: '33vh'}} /></div>
      <div className='decoration'></div>
    </div>


    <div className="splash-item" style={{margin: '20px 28px', height: '40vh', display: 'flex', justifyContent: 'center', zIndex: 100}}>

      <div style={{display: 'flex', flexWrap: 'wrap'}}>
        <img src={salesforceLogo} style={{height: '10vh', margin: '24px'}} />
        <img src={jiraLogo} style={{height: '10vh', margin: '24px'}} />
        <img src={mondayLogo} style={{height: '8vh', margin: '24px'}} />
        <img src={ZendeskLogo} style={{height: '8vh', margin: '24px'}} />

      </div>
      <div className='splash-item'>
        <div style={{fontSize: '32px', lineHeight: '32px', zIndex: 100}}>Integrate your tools </div>
        <div style={{fontSize: '24px', lineHeight: '24px', zIndex: 100, margin: '16px'}}>
            Take advantage of the tools your team already uses. With integrations you can automatically track progress and trigger alerts based on the status of objects in your CRM and project management systems like Salesforce and Jira.
        </div>
      </div>
    </div>

    </div>
    <Footer></Footer>
    </>
  );
}
export default SplashPage;