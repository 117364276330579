import { useContext, useEffect, useState } from 'react'
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { styled } from '@mui/system';
import { CalendarContext } from './calendar-dash';

const NavContainer = styled('div')({
    display: 'flex',
    height: '64px',
    width: '100%',
    flexDirection: 'row',
    padding: 8,
    alignItems: 'center'
})

const NavItem = styled('div')({
    margin: '0 4px',
    padding: 12,
    verticalAlign: 'middle',
    cursor: 'pointer'

})

const MenuButton = styled(NavItem)({

})

const TodayButton = styled(NavItem)({


})

const MoveLeftButton = styled(NavItem)({
    
})

const MoveRightButton = styled(NavItem)({
    
})


const CurrentPageDate = styled(NavItem)({
    
})

export default function TopNav() {

    const calendarContext = useContext(CalendarContext)
    return <NavContainer>
        <MenuButton>
            <IconButton color="primary" aria-label="upload picture" component="span">
                <MenuIcon />
            </IconButton>
        </MenuButton>
        <TodayButton>
            <Button variant="outlined" size="medium" onClick={() => {
                calendarContext.setToToday()
            }}>
            Today
            </Button>
        </TodayButton>
        <MoveLeftButton onClick={() => {
            const month = calendarContext.monthInView
            if (month === 0) {
             calendarContext.setDatesInView(11, calendarContext.yearInView - 1)
            } else {
                calendarContext.setDatesInView(month - 1, calendarContext.yearInView)
            }
        }}>            
            <IconButton color="primary" aria-label="upload picture" component="span">
                <ArrowBackIosIcon />
            </IconButton>
        </MoveLeftButton>
        <MoveRightButton onClick={() => {
            const month = calendarContext.monthInView
            if (month === 11) {
             calendarContext.setDatesInView(0, calendarContext.yearInView + 1)
            } else {
                calendarContext.setDatesInView(month + 1, calendarContext.yearInView)
            }
        }}>            
            <IconButton color="primary" aria-label="upload picture" component="span">
                <ArrowForwardIosIcon />
            </IconButton>
        </MoveRightButton>
        <CurrentPageDate>{calendarContext.dateInView.format('MMMM, YYYY')}</CurrentPageDate>
    </NavContainer>
}