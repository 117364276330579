import React from 'react'
import { useAuthenticator } from '@aws-amplify/ui-react';
import { Outlet, Link } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoIcon from '../LogoIcon';


export default function Base() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    const { user, signOut, authStatus } = useAuthenticator((context) => [context.user]);

    const getInitials = (name: string) => {
        const initials = name.split(' ')
        if (initials.length === 2) {
            return `${initials[0][0]}${initials[1][0]}`.toUpperCase()
        } else {
            return name[0].toUpperCase()
        }
    }
    return (
        <>
            <AppBar position="static" sx={{background: 'white', color: 'rgb(3,45,96)'}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                <LogoIcon></LogoIcon>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    href="/"
                >
                    OKR Calendar
                </Typography>
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    <Button
                        key={'pricing'}
                        onClick={handleCloseNavMenu}
                    >
                        <Link to="/nav/pricing">Pricing</Link>
                    </Button>

                    <Button
                        key={'contact'}
                        onClick={handleCloseNavMenu}
                    >
                        <Link to="/nav/contact">Contact Us</Link>
                    </Button>
                </Box>
                {user ? <Box sx={{ flexGrow: 0 }}>
                    <Tooltip title="Open settings">
                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar alt={getInitials(user?.attributes?.name || 'N A')} src="/static/images/avatar/2.jpg" />
                    </IconButton>
                    </Tooltip>
                    <Menu
                    sx={{ mt: '45px' }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                    >
                        <MenuItem key={'Profile'} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center"><Link to='/app/Profile'>Profile</Link></Typography>
                        </MenuItem>
                        <MenuItem key={'Account'} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center"><Link to='/app/Account'>Account</Link></Typography>
                        </MenuItem>
                        <MenuItem key={'Dashboard'} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center"><Link to='/app/Dashboard'>Dashboard</Link></Typography>
                        </MenuItem>
                        <MenuItem key={'sign-out'} onClick={signOut}>
                        <Typography textAlign="center">Sign Out</Typography>
                        </MenuItem>
                    </Menu>
                </Box>
                : <Link to="/login"><Button>Sign In</Button></Link>
                }
                </Toolbar>
            </Container>
            </AppBar>
            <Outlet></Outlet>
        </>
    )
}