import React from 'react'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MediationIcon from '@mui/icons-material/Mediation';

export default function LogoIcon() {
    return (
        <span style={{ display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: '8px'
            }}>
            <CalendarTodayIcon style={{position: 'absolute'}} fontSize="large"></CalendarTodayIcon>
            <MediationIcon fontSize="small" style={{padding: '0 1px', position: 'relative', top: '3px', left: '1px'}}></MediationIcon>
        </span>
    )

}