import React, { useEffect } from 'react'
import { API } from 'aws-amplify';
import { saveToken } from '../../graphql/mutations';
import { useNavigate, Link } from "react-router-dom";
import { useLocation, useSearchParams } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';




export default function JiraAuth() {
    const { user } = useAuthenticator((context) => [context.user]);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const createToken = async () => {
            if (!searchParams || !searchParams.get('code')) { return }
            const code = searchParams.get('code')
            const tokenResponse = await API.graphql({
                query: saveToken,
                variables: {
                    input: {
                        authCode: code
                    }
                }
            })
        }
        createToken()
    }, [searchParams])

    return <>Redirecting...</>
}